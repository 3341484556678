import React from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
  Container,
} from "@chakra-ui/react";

import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Link, NavLink } from "react-router-dom";

// Navigation Configuration
const NAV_ITEMS = [
  { label: "Home", href: "/" },
  {
    label: "Solutions",
    children: [
      { label: "Asset Management", href: "/asset-management" },
      { label: "8D Quality Management", href: "/quality-management" },
      { label: "Custom Workflows", href: "/custom-workflows" },
    ],
  },
  { label: "Features", href: "/features" },
  { label: "Contact Us", href: "/contactus" },
  { label: "About Us", href: "/aboutus" },
];

// Desktop Submenu Item Component
const DesktopSubNav = ({ label, href }) => {
  return (
    <Box
      as="a"
      href={href}
      role="group"
      display="block"
      p={4}
      rounded="lg"
      _hover={{ bg: useColorModeValue("blue.50", "gray.900") }}
      transition="all 0.3s ease"
    >
      <Stack direction="row" align="center" spacing={1}>
        <Box flex={1}>
          <Text _groupHover={{ color: "blue.500" }} transition="all 0.3s ease">
            {label}
          </Text>
        </Box>
        <Icon
          as={ChevronRightIcon}
          w={5}
          h={5}
          color="blue.400"
          _groupHover={{ transform: "translateX(5px)" }}
          transition="all 0.3s ease"
        />
      </Stack>
    </Box>
  );
};

// Desktop Navigation Component
const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack
      direction="row"
      spacing={{ base: 4, xl: 6 }} // Reduced spacing between items
      flex={1}
      justify="center"
      align="center"
    >
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label} whiteSpace="nowrap">
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <Box
                as="a"
                href={navItem.href ?? "#"}
                p={2}
                fontSize={{ base: "sm", xl: "md" }} // Responsive font size
                color={linkColor}
                position="relative"
                _hover={{ color: "blue.500", _after: { width: "100%" } }}
                _after={{
                  content: "''",
                  position: "absolute",
                  width: "0%",
                  height: "2px",
                  bottom: "0",
                  left: "0",
                  bg: "blue.400",
                  transition: "all 0.3s ease",
                }}
              >
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow="xl"
                bg={popoverContentBgColor}
                p={4}
                rounded="xl"
                minW="sm"
              >
                <Stack spacing={2}>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

// Mobile Navigation Item Component
const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as="a"
        href={href ?? "#"}
        justify="space-between"
        align="center"
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition="all 0.25s ease"
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align="start"
        >
          {children?.map((child) => (
            <Box as="a" key={child.label} py={2} href={child.href}>
              {child.label}
            </Box>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

// Mobile Navigation Component
const MobileNav = () => (
  <Stack
    bg={useColorModeValue("white", "gray.800")}
    borderRadius="2xl"
    p={6}
    spacing={4}
  >
    {NAV_ITEMS.map((navItem) => (
      <MobileNavItem key={navItem.label} {...navItem} />
    ))}
  </Stack>
);

// Main Navbar Component
const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box position="fixed" width="100%" top={0} zIndex={1000}>
      <Container maxW="8xl" px={{ base: 4, md: 6, lg: 4 }}>
        <Flex
          bg={useColorModeValue(
            "rgba(255, 255, 255, 0.95)",
            "rgba(26, 32, 44, 0.95)"
          )}
          minH="70px"
          py={4}
          px={{ base: 4, md: 6 }}
          borderRadius="2xl"
          backdropFilter="blur(20px)"
          boxShadow="lg"
          align="center"
          mt={4}
        >
          {/* Mobile Menu Button */}
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant="ghost"
            aria-label="Toggle Navigation"
            display={{ base: "flex", lg: "none" }}
          />

          {/* Logo */}
          <Flex
            flex={{ base: 1, lg: "auto" }}
            justify={{ base: "center", lg: "start" }}
          >
            <Link to="/">
              <Flex alignItems={"center"}>
                <Image
                  src="/assets/favicon.png"
                  h="35px"
                  alt="ServiceTiket Logo"
                />
                <Text
                  mt={1}
                  ml={2}
                  fontSize={{ base: "lg", md: "xl" }}
                  color={"blue.400"}
                  fontWeight={"bold"}
                  fontFamily={"Nunito, sans-serif"}
                >
                  ServiceTiket
                </Text>
              </Flex>
            </Link>
          </Flex>

          {/* Desktop Navigation */}
          <Flex
            display={{ base: "none", lg: "flex" }}
            justify="center"
            flex={1}
            ml={10}
          >
            <DesktopNav />
          </Flex>

          {/* CTA Button */}
          <NavLink to="/contactus">
            <Button
              display={{ base: "none", md: "block" }}
              bg="blue.400"
              color="white"
              fontWeight={"500"}
              borderRadius="lg"
              ml={2}
              _hover={{
                bg: "blue.500",
              }}
            >
              Get Started
            </Button>
          </NavLink>
        </Flex>

        {/* Mobile Navigation Menu */}
        <Collapse in={isOpen} animateOpacity>
          <Box
            bg={useColorModeValue("white", "gray.800")}
            borderRadius="2xl"
            mt={4}
            boxShadow="xl"
          >
            <MobileNav />
          </Box>
        </Collapse>
      </Container>
    </Box>
  );
};

export default Navbar;
