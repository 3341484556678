import React from "react";
import {
  Box,
  SimpleGrid,
  Heading,
  Text,
  Icon,
  VStack,
  Link,
  Container,
  Button,
  Flex,
} from "@chakra-ui/react";
import {
  FiSmartphone,
  FiSettings,
  FiDatabase,
  FiActivity,
  FiBell,
  FiLock,
  FiArrowRight,
} from "react-icons/fi";
import { IoIosArrowRoundForward } from "react-icons/io";
import SectionHeader from "./SectionHeader";
import { NavLink } from "react-router-dom";

// Feature card data configuration
const CARDS_DATA = [
  {
    title: "Take control of your assets",
    description:
      "E2E lifecycle management for your assets, from acquisition to disposal.",
    icon: FiSmartphone,
    color: "blue", // Keeping blue for mobile/tech
  },
  {
    title: "Speak your organization's language",
    description:
      "Digitize your work practices with our expertise, ensuring seamless adoption and efficiency while aligning data with your operations.",
    icon: FiDatabase,
    color: "blue", // Changed to purple for customization/flexibility
  },
  {
    title: "Collect quality data",
    description:
      "Capture high-quality data effortlessly using triggers and customizable forms tailored to your workflow needs.",
    icon: FiSettings,
    color: "blue", // Changed to teal for workflow/process
  },
 
  {
    title: "Consolidate asset history",
    description:
      "Full audit trails provide enhanced accountability and forecasting for every asset.",
    icon: FiActivity,
    color: "blue", // Changed to cyan for data/analytics
  },
  {
    title: "Custom notifications",
    description:
      "Everyone stays informed automatically for improved efficiency and planning.",
    icon: FiBell,
    color: "blue", // Changed to pink for alerts/notifications
  },
  {
    title: "Role-based access",
    description:
      "Get enhanced data security and simple onboarding for every job function in your organization.",
    icon: FiLock,
    color: "blue", // Keeping orange for security/access
  },
];

// Individual feature card component
const Card = ({ title, description, icon, color, index }) => (
  <Box
    key={index}
    bg="white"
    p={8}
    borderRadius="2xl"
    boxShadow="sm"
    transition="all 0.3s ease"
    _hover={{
      transform: "translateY(-8px)",
      boxShadow: "sm",
    }}
    position="relative"
    overflow="hidden"
    data-testid={`feature-card-${index}`}
  >
    {/* Decorative background */}
    <Box
      position="absolute"
      top={0}
      right={0}
      w="150px"
      h="150px"
      bg={`${color}.50`}
      borderBottomLeftRadius="full"
      opacity={0.3}
    />

    <VStack align="start" spacing={4} position="relative">
      {/* Icon container */}
      <Box
        bg={`${color}.50`}
        p={4}
        borderRadius="2xl"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon as={icon} w={6} h={6} color={`${color}.500`} />
      </Box>

      {/* Card content */}
      <Heading as="h3" fontSize="xl" color={`${color}.900`}>
        {title}
      </Heading>

      <Text fontSize="md" color="gray.600" lineHeight="tall">
        {description}
      </Text>

      {/* Call to action link */}
      {/* <Link
        color={`${color}.500`}
        fontWeight="bold"
        fontSize="md"
        href="#"
        display="flex"
        alignItems="center"
        _hover={{
          textDecoration: "none",
          color: `${color}.600`,
          "& > svg": { transform: "translateX(5px)" },
        }}
      >
        Learn More
        <Icon
          as={IoIosArrowRoundForward}
          ml={2}
          w={6}
          h={6}
          transition="transform 0.2s ease"
        />
      </Link> */}
    </VStack>
  </Box>
);

// Main component
const CardsSection = () => {
  return (
    <Box py={20} px={4} bg="gray.50">
      <Container maxW="8xl">
        {/* Section header */}
        <SectionHeader title="See what you can do with our asset platform" />
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={{ base: 8, lg: 12 }}
          mx="auto"
          mt={20}
        >
          {CARDS_DATA.map((feature, index) => (
            <Card key={index} {...feature} index={index} />
          ))}
        </SimpleGrid>
        <Flex justify="center" mt={16}>
          <Link href="/features">
            <Button
              size="lg"
              px={8}
              py={6}
              fontSize="md"
              fontWeight="bold"
              bg={"blue.400"}
              color="white"
              rounded="lg"
              rightIcon={<Icon as={FiArrowRight} />}
              _hover={{
                bg: "blue.500",
              }}
            >
              Explore more features
            </Button>
          </Link>
        </Flex>
      </Container>
    </Box>
  );
};

export default CardsSection;
