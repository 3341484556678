import React from "react";
import { VStack, Heading, Text } from "@chakra-ui/react";

/**
 * SectionHeader Component
 *
 * Renders a section header with a title and an optional subtitle.
 * Provides customization for colors and responsive font sizes.
 *
 * Props:
 * @param {string} title - The main title to display. (Required)
 * @param {string} subtitle - The subtitle to display below the title. (Optional)
 * @param {string} titleColor - The color for the title text. Default is "blue.900". (Optional)
 * @param {string} subtitleColor - The color for the subtitle text. Default is "gray.600". (Optional)
 */

const SectionHeader = ({
  title,
  subtitle,
  titleColor = "blue.900", // Default title color
  subtitleColor = "gray.600", // Default subtitle color
}) => {
  return (
    <VStack spacing={2} mb={0} textAlign="center">
      {/* Render title if provided */}
      {title && (
        <Heading
          fontSize={{ base: "xl", md: "4xl", lg: "4xl" }}
          letterSpacing="tight"
          color={titleColor}
          textTransform="capitalize"
          data-aos="fade-up"
        >
          {title}
        </Heading>
      )}
      {/* Render subtitle if provided */}
      {subtitle && (
        <Text
          fontSize={{ base: "md", md: "2xl" }}
          color={subtitleColor}
          maxW="4xl"
          lineHeight="tall"
          px={{ base: 4, md: 6 }}
          data-aos="fade-up"
          data-aos-delay="150"
        >
          {subtitle}
        </Text>
      )}
    </VStack>
  );
};

export default SectionHeader;
