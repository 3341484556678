import { Box } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Privacy from "../components/Privacy";
import ScrollToTop from "../components/ScrollToTop";

const PrivacyPolicy = () => {
  return (
    <Box>
      <Navbar />
      <ScrollToTop />
      <Privacy />
      <Footer />
    </Box>
  );
};

export default PrivacyPolicy;
