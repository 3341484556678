import { Box } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import ContactUsForm from "../components/Contact";
import ClientsSection from "../components/ClientSection";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import ContactCTA from "../components/ContactCTA";
import { useState, useEffect } from "react";

const ContactUs = () => {
  const [showClientSection, setShowClientSection] = useState(false);

  useEffect(() => {
    // Get the current hostname
    const hostname = window.location.hostname;

    // Check if the hostname is formobi.com or localhost
    const shouldShowClients =
      hostname === "formobi.com" ||
      hostname === "www.formobi.com" ||
      hostname === "localhost" ||
      hostname.includes("192.168.") || // For local network testing
      hostname.includes("127.0.0.1"); // For local testing

    setShowClientSection(shouldShowClients);
  }, []);

  const handleWhatsAppClick = () => {
    const phoneNumber = "919845221442";
    const message = "Hello I would like to know more about your services";
    const webWhatsappUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    const mobileWhatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Check if the device is mobile
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // Open appropriate URL based on device type
    window.open(isMobile ? mobileWhatsappUrl : webWhatsappUrl, "_blank");
  };

  return (
    <Box>
      <Navbar />
      <ScrollToTop />
      <ContactUsForm handleWhatsAppClick={handleWhatsAppClick} />

      {/* Conditionally render ClientsSection */}
      {showClientSection && <ClientsSection />}

      <ContactCTA handleWhatsAppClick={handleWhatsAppClick} />
      <Footer />
    </Box>
  );
};

export default ContactUs;
