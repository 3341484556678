import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "Nunito, sans-serif", // Apply Nunito font to all heading components
    body: "Roboto, sans-serif",   // Apply Roboto font to all text components
  },
});

export default theme;

// import { extendTheme } from "@chakra-ui/react";

// const theme = extendTheme({
//   styles: {
//     global: {
//       body: {
//         bg: "gray.50",
//         color: "gray.900",
//       },
//     },
//   },
//   global: {
//     "@keyframes float": {
//       "0%, 100%": { transform: "translateY(0)" },
//       "50%": { transform: "translateY(-20px)" },
//     },
//     "@keyframes spin": {
//       "0%": { transform: "rotate(0deg)" },
//       "100%": { transform: "rotate(360deg)" },
//     },
//   },
//   components: {
//     Button: {
//       baseStyle: {
//         fontWeight: "bold",
//         borderRadius: "xl",
//       },
//     },
//     Link: {
//       baseStyle: {
//         transition: "all 0.3s ease",
//         _hover: {
//           textDecoration: "none",
//         },
//       },
//     },
//   },
// });

