import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  VStack,
  useColorModeValue,
  Link
} from "@chakra-ui/react";
import {  NavLink } from "react-router-dom";

const CallToAction = () => {
  return (
    <Box
      as="section"
      id="call-to-action"
      bg="blue.400"
      py={20}
      position="relative"
      overflow="hidden"
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bg: "linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)",
      }}
    >
      <Container maxW="container.xl">
        <VStack
          spacing={8}
          maxW="8xl"
          mx="auto"
          textAlign="center"
          position="relative"
          zIndex={1}
        >
          <Heading as="h2" fontSize={{ base: "3xl", md: "4xl" }} color="white">
            Seamless Asset Control Starts Here!
          </Heading>

          <Text
            color="whiteAlpha.900"
            fontSize={{ base: "lg", md: "xl" }}
            maxW="3xl"
            opacity={0.9}
          >
            Enhance visibility, accuracy, and profitability across assets. Our
            experts are here to ensure a seamless transformation!
          </Text>
          <Link href="/contactus">
            <Button
              size="lg"
              variant={"outline"}
              // bg="whiteAlpha.900"
              color="white"
              px={{ base: 8, md: 12 }}
              py={7}
              fontSize={{ base: "md", md: "lg" }}
              fontWeight="bold"
              rounded="lg"
              _hover={{
                bg: "white",
                color: "blue.400",
              }}
            >
              Get Started with a Free Consultation
            </Button>
          </Link>
        </VStack>
      </Container>
    </Box>
  );
};

export default CallToAction;
