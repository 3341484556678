import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  Icon,
  useColorModeValue,
  Flex,
  Circle,
  Badge,
  Divider,
  ScaleFade,
  Image,
  Button,
  Stack,
} from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import SectionHeader from "../components/SectionHeader";
import {
  FaClipboardList,
  FaCogs,
  FaTools,
  FaCheckCircle,
  FaRocket,
  FaHeadset,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

const workflowSteps = [
  {
    icon: FaClipboardList,
    title: "Requirements & Proposal",
    description: [
      "Requirements capture",
      "Documentation and signoff",
      "Commercial proposal (PO/DO)",
    ],
    color: "blue.400",
    gradient: "linear(to-r, blue.400, blue.600)",
  },
  {
    icon: FaCogs,
    title: "Initial Implementation",
    description: [
      "Platform configurations",
      "Workflow setup",
      "Masters and Templates",
      "Staging deployment",
    ],
    color: "purple.400",
    gradient: "linear(to-r, purple.400, purple.600)",
  },
  {
    icon: FaTools,
    title: "Advanced Implementation",
    description: [
      "Feedback integration",
      "Reports & Dashboards",
      "Automated notifications",
    ],
    color: "teal.400",
    gradient: "linear(to-r, teal.400, teal.600)",
  },
  {
    icon: FaCheckCircle,
    title: "Testing & Validation",
    description: ["UAT process", "Quality assurance", "Production prep"],
    color: "pink.400",
    gradient: "linear(to-r, pink.400, pink.600)",
  },
  {
    icon: FaRocket,
    title: "Deployment",
    description: ["User onboarding", "Cutover execution", "Launch"],
    color: "orange.400",
    gradient: "linear(to-r, orange.400, orange.600)",
  },
  {
    icon: FaHeadset,
    title: "Ongoing Support",
    description: ["24/7 maintenance", "Backup & DR", "Feature updates"],
    color: "green.400",
    gradient: "linear(to-r, green.400, green.600)",
  },
];

const WorkflowCard = ({ icon, title, description, color, gradient, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, inView]);

  return (
    <Box
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <Flex
        direction="column"
        bg={useColorModeValue("white", "gray.800")}
        p={8}
        borderRadius="2xl"
        boxShadow="sm"
        height="full"
        position="relative"
      >
        <Circle
          size="60px"
          bg={color}
          //bgGradient={gradient}
          mb={6}
        >
          <Icon as={icon} w={6} h={6} color="white" />
        </Circle>

        <Badge
          alignSelf="flex-start"
          bg={color}
          color={"white"}
          px={3}
          py={2}
          borderRadius="lg"
          mb={4}
        >
          PHASE {index + 1}
        </Badge>

        <Heading
          size="md"
          mb={4}
          color={useColorModeValue("gray.800", "white")}
        >
          {title}
        </Heading>

        <Divider mb={4} />

        <VStack align="start" spacing={3}>
          {description.map((item, idx) => (
            <Flex key={idx} align="center">
              <Box
                w={2}
                h={2}
                borderRadius="full"
                ///  bgGradient={gradient}
                bg={color}
                mr={3}
              />
              <Text color={"gray.600"}>{item}</Text>
            </Flex>
          ))}
        </VStack>
        {/* Bottom Decoration */}
        <Box
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          h="4px"
          bg={color}
          // bgGradient={gradient}
          borderBottomRadius="xl"
        />
      </Flex>
    </Box>
  );
};

const Hero = () => {
  return (
    <Box
      as="section"
      py={{ base: 20, md: 28 }}
      position="relative"
      overflow="hidden"
      bg={useColorModeValue("gray.50", "gray.900")}
    >
      <Container
        maxW="8xl"
        position="relative"
        mt={{ base: 10, md: 20, lg: 0 }}
      >
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing={{ base: 16, lg: 16 }}
          align="center"
          justify="space-between"
        >
          {/* Left Column */}
          <VStack
            align="start"
            spacing={{ base: 10, md: 8 }}
            flex={1.5}
            position="relative"
          >
            <VStack align="start" spacing={6} w="full">
              <Heading
                borderRadius={"lg"}
                bg={"blackAlpha.50"}
                p={3}
                fontSize={"sm"}
                color={"blue.600"}
                fontWeight={"bold"}
                textTransform={"uppercase"}
              >
                Professional Services
              </Heading>
              <Box>
                <Heading
                  fontSize={{ base: "2xl", md: "5xl", lg: "5xl" }}
                  lineHeight="1.1"
                  letterSpacing="-0.02em"
                  fontWeight="bold"
                >
                  How{" "}
                  <Heading
                    fontSize={{ base: "2xl", md: "5xl", lg: "5xl" }}
                    lineHeight="1.1"
                    letterSpacing="-0.02em"
                    fontWeight="bold"
                    as="span"
                    color={"blue.400"}
                  >
                    Our Services
                  </Heading>{" "}
                  Work?
                </Heading>
              </Box>
              <Heading
                fontSize={{ base: "lg", md: "2xl" }}
                fontWeight="600"
                color={useColorModeValue("gray.600", "gray.300")}
                letterSpacing="-0.01em"
                lineHeight="1.4"
              >
                Leverage our expertise to transform asset management with
                precision workflows, real-time insights, and complete lifecycle
                control.
              </Heading>
            </VStack>
            {/* <NavLink to="/contactus">
              <Button
                bg="blue.400"
                color="white"
                fontWeight={"500"}
                py={7}
                px={10}
                borderRadius="lg"
                //rightIcon={<BsArrowRight />}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Get Started Today
              </Button>
            </NavLink> */}
          </VStack>

          {/* Right Column */}
          <Box flex={1} position="relative">
            <Image
              src="assets/illustration-10.png"
              alt="Hero Illustration"
              w="full"
              h="auto"
              objectFit="contain"
              loading="eager"
              quality={100}
            />
          </Box>
        </Stack>

        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacing={{ base: 8, lg: 12 }}
          mt={20}
        >
          {workflowSteps.map((step, index) => (
            <WorkflowCard key={index} {...step} index={index} />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Hero;
