import React from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  VStack,
  Container,
} from "@chakra-ui/react";

const Error404 = () => {
  return (
    <Container maxW="container.xl" h="100vh">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        position="relative"
        overflow="hidden"
      >
        {/* Decorative Elements */}
        <Box
          position="absolute"
          width="400px"
          height="400px"
          borderRadius="full"
          bg="blue.50"
          filter="blur(80px)"
          top="10%"
          left="20%"
          animation="float 6s ease-in-out infinite"
        />
        <Box
          position="absolute"
          width="300px"
          height="300px"
          borderRadius="full"
          bg="blue.100"
          filter="blur(80px)"
          bottom="10%"
          right="20%"
          animation="float 8s ease-in-out infinite"
        />

        <VStack
          spacing={8}
          zIndex="1"
          textAlign="center"
          animation="pulse 3s infinite"
        >
          <VStack spacing={4}>
            <Heading
              fontSize={{ base: "xl", md: "2xl" }}
              fontWeight="700"
              color="blue.600"
              letterSpacing="wide"
              textTransform="uppercase"
            >
              Oops! Page Not Found
            </Heading>

            <Heading
              fontSize={{ base: "120px", md: "200px", lg: "300px" }}
            //  fontWeight="900"
              bgGradient="linear(to-r, blue.400, blue.600)"
              bgClip="text"
              letterSpacing="tight"
              lineHeight="1"
              mb={{ base: 4, md: 8 }}
            >
              404
            </Heading>

            <Text
              fontSize={{ base: "lg", md: "xl" }}
              color="blue.600"
              maxW="600px"
              px={4}
            >
              We're sorry, but the page you're looking for seems to have gone on
              vacation
            </Text>
          </VStack>

          <Button
            size="lg"
            height="60px"
            px="8"
            fontSize="lg"
            bgGradient="linear(to-r, blue.400, blue.500)"
            color="white"
            borderRadius="full"
            _hover={{
              bgGradient: "linear(to-r, blue.500, blue.600)",
              transform: "translateY(-2px)",
              boxShadow: "lg",
            }}
            _active={{
              transform: "translateY(0)",
            }}
            onClick={() => (window.location.href = "/")}
            transition="all 0.2s"
          >
            Return Home
          </Button>
        </VStack>
      </Box>
    </Container>
  );
};

export default Error404;
