import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  VStack,
  Heading,
  Container,
  Icon,
  Link,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function ServiceSection() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contactus");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Box py={20} bg={"gray.50"}>
      <Container maxW="8xl">
        <Flex
          align="center"
          justify="space-between"
          gap={12}
          flexDirection={{ base: "column", lg: "row-reverse" }} // Reversed order
        >
          {/* Image Section */}
          <Box
            flex="1"
            position="relative"
            _after={{
              content: '""',
              position: "absolute",
              bottom: "-20px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "80%",
              height: "20px",
              filter: "blur(20px)",
              bg: "blackAlpha.200",
              borderRadius: "full",
            }}
          >
            <Image
              src="/assets/illustration-14.png"
              alt="Professional Services"
              w={{ base: "300px", md: "600px" }}
              h={{ base: "300px", md: "450px" }}
              objectFit="cover"
              borderRadius="3xl"
              mx="auto"
            />
          </Box>

          {/* Content Section */}
          <VStack
            flex="1"
            align={{ base: "center", lg: "flex-start" }}
            spacing={8}
            textAlign={{ base: "center", lg: "left" }}
          >
            <Box>
              <Text
                color="blue.600"
                fontWeight="semibold"
                mb={3}
                fontSize="lg"
                textTransform="uppercase"
                letterSpacing="wide"
              >
                Asset Management Solutions
              </Text>
              <Heading
                color="gray.800"
                fontSize={{ base: "3xl", md: "4xl" }}
                lineHeight="shorter"
                fontWeight="bold"
                mb={4}
              >
                Transform Your Asset Management Strategy
              </Heading>
              <Text
                fontSize={{ base: "lg", md: "xl" }}
                color="gray.600"
                lineHeight="tall"
                mb={6}
              >
                Unlock the full potential of your assets with our{" "}
                <Text as="span" color="blue.600" fontWeight="bold">
                  intelligent asset management solutions.
                </Text>{" "}
                We deliver customized solutions that seamlessly fit your
                existing workflows, ensuring optimal performance and efficiency{" "}
              </Text>
            </Box>
            <Link href="/contactus">
              <Button
                size="lg"
                bg={"blue.400"}
                color="white"
                px={8}
                py={7}
                fontSize="lg"
                // rightIcon={<Icon as={FaCalendarCheck} />}
                _hover={{
                  bg: "blue.500",
                }}
                onClick={handleClick}
              >
                Get Started Today
              </Button>
            </Link>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
}
