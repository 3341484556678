import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Error404 from "./pages/Error404";
import AboutUs from "./pages/AboutUs";
import Features from "./pages/Features";
import TermsAndConditions from "./pages/Terms&Conditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePloicy from "./pages/CookiePolicy";
import Tbd from "./pages/Tbd";
import Faq from "./pages/Faq";
import ComingSoon from "./pages/ComingSoon";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tbd" element={<Tbd />} />
        <Route path="/features" element={<Features />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePloicy />} />
        <Route path="/asset-management" element={<ComingSoon />} />
        <Route path="/quality-management" element={<ComingSoon />} />
        <Route path="/custom-workflows" element={<ComingSoon />} />
        <Route path="/support" element={<Navigate to="/contactus" replace />} />
        <Route path="/portfolio" element={<ComingSoon />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
}

export default App;
