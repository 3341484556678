import React from "react";
import {
  Box,
  Container,
  Grid,
  Link,
  Text,
  VStack,
  HStack,
  Heading,
  Icon,
  Divider,
  useColorModeValue,
  Image,
  Stack,
  Flex,
} from "@chakra-ui/react";
import {
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { NavLink } from "react-router-dom";
// Contact information configuration
const CONTACT_INFO = [
  {
    icon: IoLogoWhatsapp,
    text: "WhatsApp",
    href: "https://wa.me/919845221442?text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services.",
  },
  {
    icon: FaEnvelope,
    text: "info@servicetiket.com",
    href: "mailto:info@formobi.in",
  },
  {
    icon: FaMapMarkerAlt,
    text: "91Springboard, ORR, Mahadevapura, Bangalore 560048",
    href: "https://maps.app.goo.gl/WM2xfhiuuFhCX8Tt7",
  },
  {
    icon: FaLinkedin,
    text: "LinkedIn",
    href: "https://linkedin.com/company/servicetiket",
  },
];

export const ContactItem = ({ icon, text, href, hoverColor }) => {
  const handleClick = (e) => {
    e.preventDefault();

    // Guard clause to ensure href exists
    if (!href) return;

    if (href.startsWith("https://wa.me")) {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const webWhatsappUrl = `https://web.whatsapp.com/send?phone=919845221442&text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services.`;
      window.open(isMobile ? href : webWhatsappUrl, "_blank");
    } else {
      window.open(href, "_blank");
    }
  };

  return (
 
      <HStack spacing={3} transition="all 0.3s ease"  _hover={{ color: hoverColor, textDecoration: "none" }} cursor="pointer" onClick={handleClick}>
        <Icon as={icon} color={hoverColor} boxSize={5} />
        <Text>{text}</Text>
      </HStack>

  );
};
// Navigation sections configuration
const FOOTER_SECTIONS = {
  "Quick Links": [
    { label: "Home", href: "/" },
    { label: "About Us", href: "/aboutus" },
  ],
  "Useful Links": [
    { label: "Portfolio", href: "/portfolio" },
    { label: "Contact Us", href: "/contactus" },
  ],
  Support: [
    { label: "FAQs", href: "/faqs" },
    { label: "Support", href: "/support" },
  ],
  Other: [
    { label: "Privacy Policy", href: "/privacy-policy" },
    { label: "Terms & Conditions", href: "/terms-conditions" },
  ],
};

export const FooterLink = ({ href, label, hoverColor }) => (
  <Link
    href={href}
    _hover={{
      color: hoverColor,
      textDecoration: "none",
      transform: "translateX(5px)",
    }}
    transition="all 0.3s ease"
  >
    {label}
  </Link>
);

// Legal links configuration
const LEGAL_LINKS = [
  { label: "Privacy Policy", href: "/privacy-policy" },
  { label: "Terms & Conditions", href: "/terms-conditions" },
  { label: "Cookie Policy", href: "/cookie-policy" },
];

export const LegalLink = ({ label, href, hoverColor }) => (
  <Link href={href} _hover={{ color: hoverColor }}>
    {label}
  </Link>
);

const Footer = () => {
  // Theme colors
  const colors = {
    bg: useColorModeValue("gray.50", "gray.900"),
    text: useColorModeValue("gray.600", "gray.400"),
    heading: useColorModeValue("gray.800", "white"),
    border: useColorModeValue("gray.200", "gray.700"),
    hover: useColorModeValue("blue.900", "blue.300"),
  };

  return (
    <Box
      as="footer"
      bg={"gray.50"}
      color={colors.text}
      borderTop={"0.5px solid"}
      borderTopColor={"gray.100"}
    >
      <Container maxW="8xl" py={12}>
        {/* Main Grid Layout */}
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "2fr 1fr 1fr 1fr 1fr",
          }}
          gap={12}
        >
          {/* Company Information Section */}
          <VStack align="start" spacing={5}>
            <NavLink to="/" aria-label="Home">
              <Flex alignItems={"center"}>
                <Image
                  src="/assets/favicon.png"
                  h="40px"
                  alt="ServiceTiket Logo"
                />
                <Text
                  mt={1}
                  ml={2}
                  fontSize={{ base: "lg", md: "2xl" }}
                  color={"blue.400"}
                  fontWeight={"bold"}
                  fontFamily={"Nunito, sans-serif"}
                >
                  ServiceTiket
                </Text>
              </Flex>
            </NavLink>
            <Text lineHeight="tall">
              Empowering businesses with robust tools to manage geographically
              distributed assets effortlessly, ensuring transparency and
              control.{" "}
            </Text>
            <VStack align="start" spacing={3}>
              {CONTACT_INFO.map((item, index) => (
                <ContactItem
                  key={index}
                  icon={item.icon}
                  text={item.text}
                  hoverColor={colors.hover}
                  href={item.href}
                />
              ))}
            </VStack>
          </VStack>

          {/* Navigation Sections */}
          {Object.entries(FOOTER_SECTIONS).map(([title, links]) => (
            <VStack key={title} align="start" spacing={4}>
              <Heading
                size="sm"
                color={colors.heading}
                fontWeight="bold"
                textTransform="uppercase"
              >
                {title}
              </Heading>
              <VStack align="start" spacing={3}>
                {links.map((link, index) => (
                  <FooterLink
                    key={index}
                    href={link.href}
                    label={link.label}
                    hoverColor={colors.hover}
                  />
                ))}
              </VStack>
            </VStack>
          ))}
        </Grid>

        <Divider my={8} borderColor={colors.border} />

        {/* Footer Bottom Section */}
        <Stack
          direction={{ base: "column", md: "block" }}
          justify="space-between"
          align="center"
          spacing={{ base: 6, md: 0 }}
        >
          {/* Copyright Notice */}
          <Text textAlign={{ base: "center", md: "center" }}>
            © {new Date().getFullYear()} ServiceTiket. All rights reserved.
          </Text>

          {/* Legal Links */}
          {/* <HStack
            spacing={6}
            wrap="wrap"
            justify={{ base: "center", md: "flex-end" }}
          >
            {LEGAL_LINKS.map((item, index) => (
              <LegalLink
                key={index}
                label={item.label}
                href={item.href}
                hoverColor={colors.hover}
              />
            ))}
          </HStack> */}
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
