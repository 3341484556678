import { Box } from "@chakra-ui/react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Terms from "../components/Terms";
import ScrollToTop from "../components/ScrollToTop";

const TermsAndConditions = () => {
  return (
    <Box bg="gray.50" minH="100vh">
      <Navbar />
      <ScrollToTop />
      <Terms />
      <Footer />
    </Box>
  );
};

export default TermsAndConditions;
