import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  Image,
  VStack,
  HStack,
  Icon,
  useColorModeValue,
  Divider,
  Button,
  Avatar,
  Stat,
  StatNumber,
  StatLabel,
  Flex,
} from "@chakra-ui/react";
import {
  FaHeart,
  FaLightbulb,
  FaUsers,
  FaAward,
  FaQuoteLeft,
  FaGlobe,
} from "react-icons/fa";
import Navbar from "../components/Navbar";
import ScrollToTop from "../components/ScrollToTop";
import Footer from "../components/Footer";
import PandaExpertSection from "../components/CallToAction3";
import AboutHero from "../components/About";
import ServiceSection from "../components/CallToAction4";
import OurTeam from "../components/OurTeam";
import Test from "../components/Test";
import AboutHero2 from "../components/About2";

const AboutUs = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");

  const features = [
    {
      icon: FaHeart,
      title: "Our Mission",
      description:
        "To deliver exceptional value and innovative solutions that empower businesses and individuals to thrive in the digital age.",
    },
    {
      icon: FaLightbulb,
      title: "Our Vision",
      description:
        "To be the leading force in technological advancement, creating positive impact through cutting-edge solutions.",
    },
    {
      icon: FaUsers,
      title: "Our Values",
      description:
        "Integrity, innovation, collaboration, and excellence are the cornerstones of our organization.",
    },
  ];

  const teamMembers = [
    {
      name: "Sarah Johnson",
      position: "CEO & Founder",
      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
      bio: "Visionary leader with 15+ years of industry experience",
    },
    {
      name: "Michael Chen",
      position: "CTO",
      image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d",
      bio: "Tech innovator and blockchain enthusiast",
    },
    {
      name: "Emily Rodriguez",
      position: "Design Director",
      image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80",
      bio: "Award-winning designer with global recognition",
    },
    {
      name: "David Kim",
      position: "Head of Innovation",
      image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e",
      bio: "Leading breakthrough technological advancements",
    },
  ];

  const awards = [
    {
      year: "2023",
      title: "Innovation Excellence Award",
      organization: "Tech Leaders Association",
    },
    {
      year: "2022",
      title: "Best Workplace Culture",
      organization: "HR Excellence Institute",
    },
    {
      year: "2021",
      title: "Sustainability Champion",
      organization: "Green Tech Alliance",
    },
  ];

  const testimonials = [
    {
      text: "Working with this team has transformed our business completely. They're simply outstanding!",
      author: "Jane Cooper",
      position: "CEO, TechCorp",
      avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
    },
    {
      text: "Their innovative approach and dedication to excellence sets them apart from the competition.",
      author: "Alex Thompson",
      position: "CTO, InnovateX",
      avatar: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d",
    },
  ];

  return (
    <Box>
      <Navbar />
      <ScrollToTop />
      <AboutHero />
      {/* <AboutHero2 /> */}
      {/* <OurTeam/> */}
      {/* <ServiceSection /> */}
      <PandaExpertSection />
      <Footer />
    </Box>
  );
};

export default AboutUs;
