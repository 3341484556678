import {
    Box,
    Flex,
    Image,
    Text,
    Button,
    VStack,
    Heading,
    Container,
  } from "@chakra-ui/react";
  
  export default function ContactCTA({handleWhatsAppClick}) {
    return (
      <Box
        py={20}
        position="relative"
        overflow="hidden"
      >
        <Container maxW="8xl" bg={"white"}>
          <Flex
            align="center"
            justify="space-between"
            gap={12}
            flexDirection={{ base: "column", lg: "row" }}
          >
            {/* Image Section */}
            <Box
              flex="1"
              position="relative"
              _after={{
                content: '""',
                position: "absolute",
                bottom: "-20px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "80%",
                height: "20px",
                filter: "blur(20px)",
                bg: "blackAlpha.200",
                borderRadius: "full",
              }}
            >
              <Image
                src="/assets/illustration-16.png"
                alt="Support Team"
                w={{ base: "240px", md: "400px" }}
                h={{ base: "240px", md: "400px" }}
                objectFit="cover"
                borderRadius="2xl"
                mx="auto"
              />
            </Box>
  
            {/* Content Section */}
            <VStack
              flex="1"
              align={{ base: "center", lg: "flex-start" }}
              spacing={6}
              textAlign={{ base: "center", lg: "left" }}
            >
              <Box>
                <Text
                  color="blue.600"
                  fontWeight="semibold"
                  mb={3}
                  fontSize="lg"
                  textTransform="uppercase"
                  letterSpacing="wide"
                >
                  24/7 Support
                </Text>
                <Heading
                  color="gray.800"
                  fontSize={{ base: "3xl", md: "4xl" }}
                  lineHeight="shorter"
                  fontWeight="bold"
                  mb={4}
                >
                  Ready to Help You Succeed
                </Heading>
                <Text
                  fontSize={{ base: "lg", md: "xl" }}
                  color="gray.600"
                  lineHeight="tall"
                  mb={6}
                >
                  Our dedicated support team is available{" "}
                  <Text as="span" color="blue.600" fontWeight="bold">
                    around the clock
                  </Text>{" "}
                  to assist you with any questions or technical needs you may have.
                </Text>
              </Box>
  
              <Button
                size="lg"
                bg={"blue.400"}
                color="white"
                px={8}
                py={7}
                fontSize="lg"
                _hover={{
                  bg: "blue.500"
                }}
                onClick={handleWhatsAppClick}
              >
                Get Support Now
              </Button>
            </VStack>
          </Flex>
        </Container>
      </Box>
    );
  }
  