import { Box } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import FAQSection from "../components/Faq";
import ScrollToTop from "../components/ScrollToTop";
import ConsultationSection from "../components/CallToAction3";
const FAQPage = () => {
  return (
    <Box>
      <Navbar />
      <ScrollToTop />
      <FAQSection />
      <ConsultationSection />
      <Footer />
    </Box>
  );
};

export default FAQPage;
