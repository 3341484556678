import {
  Box,
  Container,
  Heading,
  Text,
  Flex,
  Image,
  Stack,
  VStack,
  useColorModeValue,
  Badge,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const AboutHero = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.700", "gray.100");
  const accentColor = useColorModeValue("blue.500", "blue.300");
  const sectionBg = useColorModeValue("white", "gray.900");
  const badgeBg = useColorModeValue("blue.50", "blue.900");

  return (
    <Box as="section" bg={sectionBg} overflow="hidden">
      {/* First Section */}
      <Container maxW="8xl" pt={{ base: 16, md: 24 }}>
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing={{ base: 16, lg: 16 }}
          align="center"
          justify="space-between"
        >
          {/* Left Column */}
          <VStack
            align="start"
            spacing={{ base: 10, md: 8 }}
            flex={1.5}
            position="relative"
          >
            <VStack align="start" spacing={6} w="full">
              <Heading
                borderRadius={"lg"}
                bg={"blackAlpha.50"}
                p={3}
                fontSize={"sm"}
                color={"blue.600"}
                fontWeight={"bold"}
              >
                ABOUT US
              </Heading>
              <Box>
                <Heading
                    color="gray.800"
                    fontSize={{ base: "3xl", md: "4xl" }}
                    lineHeight="shorter"
                    fontWeight="bold"
                >
                  Formobi Solutions
                </Heading>
                {/* <Heading
                    as="span"
                    color={useColorModeValue("gray.700", "gray.100")}
                    fontSize={{ base: "2xl", md: "5xl", lg: "5xl" }}
                  >
                    The Future of Asset Management
                  </Heading>  */}
              </Box>
              <Text fontSize="lg" lineHeight="tall" color={"gray.700"} mb={6}>
                Formobi Solutions Pvt. Ltd., founded in 2015, is led by
                technology professionals with{" "}
                <Text as="b" color={"blue.400"}>
                  25+ years of experience
                </Text>{" "}
                at Wipro, Cisco, Amber Networks (acquired by Nokia), and Nokia.
                <br />
                <br />
                We specialize in{" "}
                <Text as="b" color={"blue.400"}>
                  business solution consulting
                </Text>
                , helping organizations digitize work practices with a strong
                focus on{" "}
                <Text as="b" color={"blue.400"}>
                  data quality
                </Text>
                . Our expertise enables enterprises to streamline operations,
                improve decision-making, and enhance overall efficiency.
              </Text>
            </VStack>

            {/* <Text
                  fontSize={{ base: "md", md: "lg" }}
                  color={textColor}
                  lineHeight="1.8"
                  maxW="600px"
                  fontWeight="400"
                >
                  Digitised workflows in days
                </Text> */}

            {/* <NavLink to="/contactus">
                <Button
                  bg="blue.400"
                  color="white"
                  fontWeight={"500"}
                  py={7}
                  px={10}
                  borderRadius="lg"
                  //rightIcon={<BsArrowRight />}
                  _hover={{
                    bg: "blue.500",
                  }}
                >
                  Free consultation
                </Button>
              </NavLink> */}
          </VStack>

          {/* Right Column */}
          <Box flex={1} position="relative">
            <Image
              src="assets/illustration-20.png"
              alt="Hero Illustration"
              w="full"
              h="auto"
              objectFit="contain"
              loading="eager"
              quality={100}
            />
          </Box>
        </Stack>
      </Container>

      {/* Second Section with Reversed Layout */}
      <Box bg={bgColor} py={{ base: 16, md: 10 }}>
        <Container maxW="8xl">
          <Stack
            direction={{ base: "column", lg: "row" }}
            spacing={{ base: 16, lg: 16 }}
            align="center"
            justify="space-between"
          >
            {/* Left Column */}
            <Box flex={1} position="relative">
              <Image
                src="assets/illustration-21.png"
                alt="Hero Illustration"
                w="full"
                h="auto"
                objectFit="contain"
                loading="eager"
                quality={100}
              />
            </Box>
            {/* Right Column */}
            <VStack
              align="start"
              spacing={{ base: 10, md: 8 }}
              flex={1.5}
              position="relative"
            >
              <VStack align="start" spacing={6} w="full">
                <Heading
                  borderRadius={"lg"}
                  bg={"blackAlpha.50"}
                  p={3}
                  fontSize={"sm"}
                  color={"blue.600"}
                  fontWeight={"bold"}
                >
                  OUR FLAGSHIP PRODUCT
                </Heading>
                <Box>
                  <Heading
                    color="gray.800"
                    fontSize={{ base: "3xl", md: "4xl" }}
                    lineHeight="shorter"
                    fontWeight="bold"
                  >
                    ServiceTiket
                  </Heading>
                  {/* <Heading
                    as="span"
                    color={useColorModeValue("gray.700", "gray.100")}
                    fontSize={{ base: "2xl", md: "5xl", lg: "5xl" }}
                  >
                    The Future of Asset Management
                  </Heading>  */}
                </Box>
                <Text fontSize="lg" lineHeight="tall" color={"gray.700"} mb={6}>
                  ServiceTiket is the flagship product of Formobi Solutions,
                  designed as a highly configurable SaaS platform for{" "}
                  <Text as="b" color={"blue.400"}>
                    Workflow, Asset Lifecycle, and Service Management{" "}
                  </Text>{" "}
                  . It helps enterprises manage assets, merchandising, and
                  service operations efficiently while ensuring{" "}
                  <Text as="b" color={"blue.400"}>
                    data integrity
                  </Text>{" "}
                  and{" "}
                  <Text as="b" color={"blue.400"}>
                    cost optimization
                  </Text>
                  .
                  <br />
                  <br />
                  Its{" "}
                  <Text as="b" color={"blue.400"}>
                    customizable workflows
                  </Text>{" "}
                  support enterprise structures, roles, and visibility, enabling
                  seamless digital transformation.
                </Text>
              </VStack>

              {/* <Text
                  fontSize={{ base: "md", md: "lg" }}
                  color={textColor}
                  lineHeight="1.8"
                  maxW="600px"
                  fontWeight="400"
                >
                  Digitised workflows in days
                </Text> */}

              {/* <NavLink to="/contactus">
                <Button
                  bg="blue.400"
                  color="white"
                  fontWeight={"500"}
                  py={7}
                  px={10}
                  borderRadius="lg"
                  //rightIcon={<BsArrowRight />}
                  _hover={{
                    bg: "blue.500",
                  }}
                >
                  Free consultation
                </Button>
              </NavLink> */}
            </VStack>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutHero;
