import { Box } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import ScrollToTop from "../components/ScrollToTop";
import Hero from "../components/TBD/Hero";
import Hero2 from "../components/TBD/Hero2";
import AssetLifecycleTimeline from "../components/AssetLifecycleTimeline";
import CardsSection from "../components/CardsSection2";
import CardsSection2 from "../components/CardsSection";
import CallToAction from "../components/CallToAction";
import CallToAction2 from "../components/CalltoAction2";
import FAQ from "../components/Faq";
import Footer from "../components/Footer";
import Pyramid from "../components/Pyramid";
import Features from "./Features";
import CTAGradient from "../components/TBD/CallToActions";

const Tbd = () => {
  return (
    <Box>
      {/* <Navbar /> */}
      <ScrollToTop />
      <Hero />
      <Hero2 />
       {/* <Pyramid/>  */}
      <AssetLifecycleTimeline />
      <CardsSection />
      <CTAGradient />
      <CardsSection2 />
      <CallToAction />
      <FAQ />
      {/* <Contact /> */}
      <Footer />
       {/* <ClientsSection /> */} 
    </Box>
  );
};

export default Tbd;
