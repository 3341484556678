import { IconButton } from "@chakra-ui/react";
import { BiChevronUp, BiGame } from "react-icons/bi";

const ScrollToTop = () => {
  // Function to scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Button styles
  const buttonStyles = {
    position: "fixed",
    bottom: "6",
    right: "6",
    zIndex: "tooltip",
    size: "lg",
    bg: "blue.400",
    color: "white",
    fontSize: "24px",
    rounded: "full",
  
    opacity: 1,
    visibility: "visible",
    transition: "all 0.3s ease",
    _hover: {
      bg: "blue.500",
    },
  };

  return (
    <IconButton
      aria-label="Scroll to top"
      icon={<BiChevronUp />}
      onClick={scrollToTop}
      {...buttonStyles}
    />
  );
};

export default ScrollToTop;
