import { Box } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import ScrollToTop from "../components/ScrollToTop";
import Hero from "../components/Hero";
import AssetLifecycleTimeline from "../components/AssetLifecycleTimeline";
import CardsSection from "../components/CardsSection2";
import CardsSection2 from "../components/CardsSection";
import CallToAction from "../components/CallToAction";
import CallToAction2 from "../components/CalltoAction2";
import FAQ from "../components/Faq";
import Footer from "../components/Footer";
import Pyramid from "../components/Pyramid";
import Features from "./Features";
import PandaExpertSection from "../components/CallToAction3";
import ServiceSection from "../components/CallToAction4";

const Home = () => {
  return (
    <Box>
      <Navbar />
      <ScrollToTop />
      <Hero />
      <AssetLifecycleTimeline />
      <ServiceSection />
      <CardsSection />
      <CallToAction />
      <CardsSection2 />
      <PandaExpertSection />
      <Footer />
    </Box>
  );
};

export default Home;
