import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
  Input,
  Textarea,
  Button,
  Image,
  FormControl,
  useColorModeValue,
  Icon,
  Divider,
  AbsoluteCenter,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaPaperPlane, FaWhatsapp } from "react-icons/fa";
import axios from "axios";
const ContactHero = ({ handleWhatsAppClick }) => {
  const formBg = useColorModeValue(
    "rgba(255, 255, 255, 0.95)",
    "rgba(26, 32, 44, 0.95)"
  );
  const textColor = useColorModeValue("gray.700", "gray.200");
  const accentColor = "blue.400";
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const toast = useToast(); // Chakra UI toast
  const [loading, setLoading] = useState(false); // Loading state

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    // Form validation
    if (!formData.name || !formData.email || !formData.message) {
      toast({
        title: "All fields are required!",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
        variant: "left-accent",
      });
      return;
    }

    setLoading(true); // Start loading

    try {
      await axios.post("/api/support", formData);

      toast({
        title: "Message sent successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
        variant: "left-accent",
      });

      // Reset form after successful submission
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error("Error submitting support request:", error);

      toast({
        title: "Failed to send message. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
        variant: "left-accent",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Box as="section">
      <Container maxW="8xl" mt={"20"}>
        <Flex
          direction={{ base: "column", lg: "row" }}
          align="center"
          justify="space-between"
          gap={16}
        >
          {/* Left Content with Image */}
          <VStack
            align="flex-start"
            spacing={8}
            flex={1}
            pt={{ base: 10, lg: 20 }}
          >
            <VStack align="start" spacing={6}>
              <Heading
                borderRadius={"lg"}
                bg={"blackAlpha.50"}
                p={3}
                fontSize={"sm"}
                color={"blue.600"}
                fontWeight={"bold"}
                textTransform={"uppercase"}
              >
                Let's Connect
              </Heading>
              <Heading
                fontSize={{ base: "2xl", md: "5xl", lg: "5xl" }}
                lineHeight="1.1"
                letterSpacing="-0.02em"
                fontWeight="bold"
              >
                Turn Your Assets
                <Text as="span" color={accentColor}>
                  {" "}
                  Into Growth Drivers
                </Text>
              </Heading>
              <Heading
                fontSize={{ base: "lg", md: "2xl" }}
                fontWeight="600"
                color={useColorModeValue("gray.600", "gray.300")}
                letterSpacing="-0.01em"
                lineHeight="1.4"
              >
                Ready to Elevate Your Asset Management? Our experts are here to
                offer a Free Consultation and craft solutions tailored to your
                work practices - delivered in days.
              </Heading>
            </VStack>

            {/* Hero Image */}
            <Image
              src="/assets/illustration-15.png" // Add your image path here
              alt="Contact Illustration"
              w="full"
              maxW="500px"
              objectFit="contain"
              mt={8}
            />
          </VStack>

          {/* Contact Form */}
          <Box
            bg={formBg}
            p={10}
            borderRadius="2xl"
            shadow="xl"
            backdropFilter="blur(10px)"
            border="1px solid"
            borderColor={useColorModeValue("gray.100", "gray.700")}
            w={{ base: "full", lg: "500px" }}
          >
            <VStack spacing={8}>
              <VStack spacing={2} align="flex-start" w="full">
                <Heading size="md" color={textColor}>
                  Send us a message
                </Heading>
                <Text color={useColorModeValue("gray.600", "gray.400")}>
                  We’re on it and will respond shortly.
                </Text>
              </VStack>

              <VStack spacing={4} w="full">
                <FormControl>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Your Name"
                    bg={useColorModeValue("white", "gray.800")}
                    border="1px"
                    borderColor={useColorModeValue("gray.200", "gray.600")}
                    _hover={{ borderColor: accentColor }}
                    _focus={{
                      borderColor: accentColor,
                      ring: 2,
                      ringColor: `${accentColor}30`,
                    }}
                    h={14}
                  />
                </FormControl>

                <FormControl>
                  <Input
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    type="email"
                    placeholder="Your Email"
                    bg={useColorModeValue("white", "gray.800")}
                    border="1px"
                    borderColor={useColorModeValue("gray.200", "gray.600")}
                    _hover={{ borderColor: accentColor }}
                    _focus={{
                      borderColor: accentColor,
                      ring: 2,
                      ringColor: `${accentColor}30`,
                    }}
                    h={14}
                  />
                </FormControl>

                <FormControl>
                  <Textarea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder="Your Message"
                    rows={5}
                    bg={useColorModeValue("white", "gray.800")}
                    border="1px"
                    borderColor={useColorModeValue("gray.200", "gray.600")}
                    _hover={{ borderColor: accentColor }}
                    _focus={{
                      borderColor: accentColor,
                      ring: 2,
                      ringColor: `${accentColor}30`,
                    }}
                  />
                </FormControl>

                <Button
                  leftIcon={<FaPaperPlane />}
                  bg={accentColor}
                  color="white"
                  size="lg"
                  w="full"
                  borderRadius="lg"
                  h={14}
                  _hover={{
                    bg: "blue.500",
                  }}
                  onClick={handleSubmit}
                >
                  Send Message
                </Button>

                <Box position="relative" w={"full"} py={2}>
                  <Divider borderColor="gray.300" />
                  <AbsoluteCenter color={"gray.500"} bg="white" px="4">
                    or
                  </AbsoluteCenter>
                </Box>

                <Button
                  leftIcon={<FaWhatsapp size={24} />}
                  bg="green.400"
                  color="white"
                  size="lg"
                  w="full"
                  borderRadius="lg"
                  h={14}
                  _hover={{
                    bg: "green.500",
                  }}
                  onClick={handleWhatsAppClick}
                >
                  Connect on WhatsApp
                </Button>
              </VStack>
            </VStack>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default ContactHero;
