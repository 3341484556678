'use client'

import { useState, useEffect } from 'react'
import {
  Box,
  Flex,
  Button,
  Image,
  Link,
  Text,
  Container,
  VStack,
  HStack,
  IconButton,
  useDisclosure,
  Grid,
} from '@chakra-ui/react'
import { keyframes } from '@emotion/react'

// Animation keyframes
const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`

const shine = keyframes`
  0% { background-position: -200% center; }
  100% { background-position: 200% center; }
`

const IllustrationElements = () => (
  <Box position="absolute" width="100%" height="100%" overflow="hidden" zIndex={0}>
    <Box
      position="absolute"
      right="10%"
      top="20%"
      width={{ base: "40px", md: "60px" }}
      height={{ base: "40px", md: "60px" }}
      borderRadius="16px"
      bg="linear-gradient(135deg, #60A5FA 0%, #3B82F6 100%)"
      transform="rotate(45deg)"
      animation={`${float} 6s ease-in-out infinite`}
      boxShadow="lg"
      _hover={{ transform: "rotate(60deg) scale(1.1)" }}
      transition="all 0.3s"
    />
    
    <Box
      position="absolute"
      left="15%"
      top="30%"
      width={{ base: "30px", md: "50px" }}
      height={{ base: "30px", md: "50px" }}
      borderRadius="full"
      background="linear-gradient(45deg, #93C5FD 0%, #60A5FA 100%)"
      animation={`${float} 8s ease-in-out infinite`}
      boxShadow="2xl"
      filter="blur(5px)"
    />

    <Box
      position="absolute"
      right="25%"
      bottom="20%"
      width={{ base: "60px", md: "100px" }}
      height={{ base: "60px", md: "100px" }}
      background="linear-gradient(180deg, #DBEAFE 0%, #93C5FD 100%)"
      borderRadius="full"
      filter="blur(25px)"
      animation={`${float} 7s ease-in-out infinite`}
    />
  </Box>
)

export default function EnhancedBlueHero() {
  return (
    <Box position="relative" overflow="hidden" minH="100vh">
      {/* Modern Gradient Background */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="linear-gradient(170deg, #EFF6FF 0%, #FFFFFF 100%)"
        opacity="0.9"
      />

      <IllustrationElements />

      <Container maxW="8xl" position="relative" zIndex={1}>
        <Grid
          templateColumns={{ base: "1fr", lg: "1fr 1fr" }}
          gap={10}
          alignItems="center"
          py={{ base: 16, md: 24 }}
        >
          <VStack 
            spacing={8} 
            alignItems={{ base: "center", lg: "flex-start" }}
            textAlign={{ base: "center", lg: "left" }}
          >
            <Box
              p={4}
              bg="white"
              boxShadow="xl"
              borderRadius="full"
              border="1px solid"
              borderColor="blue.100"
              display="inline-flex"
              alignItems="center"
              backdropFilter="blur(10px)"
              background="rgba(255, 255, 255, 0.9)"
            >
              <Text color="blue.900" fontWeight="medium">
                🚀 Digitized workflows in days{' '}
                <Link 
                  color="blue.500" 
                  fontWeight="bold" 
                  href="#"
                  _hover={{ 
                    textDecoration: "none",
                    color: "blue.600" 
                  }}
                >
                  Learn more →
                </Link>
              </Text>
            </Box>

            <Text
              as="h1"
              fontSize={{ base: '4xl', md: '5xl', lg: '6xl' }}
              fontWeight="extrabold"
              bgGradient="linear(to-r, blue.600, blue.400)"
              bgClip="text"
              lineHeight="1.1"
            >
              ServiceTicket: The Future of Asset Management
            </Text>

            <Text
              fontSize={{ base: 'lg', md: 'xl' }}
              color="blue.600"
              maxW="2xl"
              lineHeight="tall"
            >
              E2E asset management, demand planning, eKYC, e-agreements,
              barcoding, placements, operations, audits, and compliances
            </Text>

            <HStack spacing={6} pt={8}>
              <Button
                size="lg"
                bgGradient="linear(to-r, blue.500, blue.600)"
                color="white"
                px={8}
                py={7}
                _hover={{
                  bgGradient: "linear(to-r, blue.600, blue.700)",
                  transform: "translateY(-2px)",
                  boxShadow: "xl",
                }}
                transition="all 0.3s"
                rounded="full"
              >
                Get Started Now
              </Button>
              <Button
                size="lg"
                variant="ghost"
                color="blue.500"
                px={8}
                py={7}
                _hover={{
                  bg: "blue.50",
                  transform: "translateY(-2px)",
                }}
                rounded="full"
              >
                Free Consultation →
              </Button>
            </HStack>
          </VStack>

          <Box 
            display={{ base: "none", lg: "block" }}
            position="relative"
          >
            <Image
              src="/hero-dashboard.png" // Add your image path here
              alt="Dashboard Preview"
              width="full"
              height="auto"
              objectFit="contain"
              rounded="2xl"
              shadow="2xl"
              transform="perspective(1000px) rotateY(-5deg) rotateX(5deg)"
              transition="all 0.3s"
              _hover={{
                transform: "perspective(1000px) rotateY(-2deg) rotateX(2deg)",
              }}
            />
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}
