import React from "react";
import {
  Box,
  chakra,
  Container,
  Text,
  HStack,
  VStack,
  Flex,
  useColorModeValue,
  useBreakpointValue,
  Heading,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import SectionHeader from "./SectionHeader";

// MotionBox for adding animations using Framer Motion
const MotionBox = motion(Box);

// Define the stages of the Asset Lifecycle
const ASSET_LIFECYCLE_STAGES = [
  {
    id: 1,
    title: "Demand Planning",
    description:
      "Demand assessments, planning and budget management ensuring timely asset acquisition to meet evolving business needs.",
  },
  {
    id: 2,
    title: "Procurement",
    description:
      "Streamline purchasing process and delivery management for geographically spread operations. GRN and asset health check.",
  },
  {
    id: 3,
    title: "e-Agreements",
    description:
      "Onboard customers in minutes with automated e-KYC and leasing e-agreements, ensuring a seamless experience while protecting assets legally.",
  },

  {
    id: 4,
    title: "Field Deployment",
    description:
      "Manage asset installation and configuration at geographically spread locations, ensuring seamless integration with existing systems and infrastructure.",
  },
  {
    id: 5,
    title: "Asset Productivity",
    description:
      "Track asset productivity and collect insights on utilization across locations.",
  },
  {
    id: 6,
    title: "Asset Movements",
    description:
      "Swift and efficient asset transfers with enhanced traceability and reduced downtime.",
  },
  {
    id: 7,
    title: "Maintenance & Repair",
    description:
      "Schedule preventative maintenance, track repair requests, and manage service providers efficiently for optimal asset performance.",
  },
  {
    id: 8,
    title: "Asset Disposal",
    description:
      "Track asset retirement, manage end-of-life disposal, and ensure adherence to environmental regulations.",
  },
];

/**
 * TimelineCard Component
 * Displays individual timeline stage with title and description.
 */
export const TimelineCard = ({ id, title, description }) => {
  return (
    <Box
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: id * 0.1 }}
      flex={1}
    >
      <HStack
        p={{ base: 6, sm: 8 }}
        bg={useColorModeValue("white", "gray.800")}
        spacing={5}
        rounded="xl"
        borderWidth="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        boxShadow="sm"
        position="relative"
        // _hover={{
        //   transform: "translateY(-5px)",
        //   boxShadow: "2xl",
        //   borderColor: "blue.400",
        // }}
        // transition="all 0.3s"
        mt={{ base: 4, md: -14 }}
      >
        <VStack spacing={4} align="start" flex={1}>
          {/* Title of the stage */}
          <Heading
            fontSize={{ base: "md", md: "xl" }}
            color={useColorModeValue("blue.600", "blue.300")}
            letterSpacing="tight"
          >
            {title}
          </Heading>
          {/* Description of the stage */}
          <Text
            fontSize={{ base: "md", md: "lg" }}
            color={useColorModeValue("gray.600", "gray.300")}
            lineHeight="tall"
          >
            {description}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};

/**
 * TimelineDivider Component
 * Displays the vertical connector and stage number.
 */
export const TimelineDivider = ({ number }) => {
  return (
    <Flex
      pos="relative"
      alignItems="center"
      mr={{ base: "40px", md: "40px" }}
      ml={{ base: "0", md: "40px" }}
    >
      {/* Vertical line connecting the stages */}
      <chakra.span
        position="absolute"
        left="50%"
        height="calc(100% + 10px)"
        border="1px solid"
        borderColor={useColorModeValue("gray.300", "blue.700")}
        top="0px"
      />
      {/* Number indicator for the stage */}
      <Box pos="relative" width="40px" height="40px">
        <Flex
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="100%"
          height="100%"
          bg={useColorModeValue("white", "gray.800")}
          border="1px solid"
          borderColor="gray.300"
          rounded="md"
          alignItems="center"
          justifyContent="center"
          fontSize="md"
          color="gray.500"
        >
          {number}
        </Flex>
        {/* Horizontal connector between the stage and the card */}
        <chakra.span
          position="absolute"
          width="40px"
          height="2px"
          bg="gray.300"
          top="50%"
          left={{ base: "40px", md: number % 2 === 0 ? "40px" : "-40px" }}
          transform="translateY(-50%)"
        />
      </Box>
    </Flex>
  );
};

/**
 * EmptyCard Component
 * Acts as a spacer for desktop layout alignment.
 */
const EmptyCard = () => {
  return (
    <Box
      flex={{ base: 0, md: 1 }}
      p={{ base: 0, md: 0 }}
      bg="transparent"
      height={{ base: "20px", md: "40px" }}
    ></Box>
  );
};

/**
 * AssetLifecycleTimeline Component
 * Displays the entire timeline for the asset lifecycle stages.
 */
const AssetLifecycleTimeline = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <Container maxWidth="8xl" p={{ base: 2, sm: 10 }} mt={10}>
      <VStack spacing={{ base: 8, lg: 16 }}>
        {/* Header Section */}
        <SectionHeader title="Streamlined Asset Management From Start to Finish" />
        {/* Timeline Section */}
        <Box mt={20}>
          {ASSET_LIFECYCLE_STAGES.map((stage) => (
            <Flex key={stage.id}>
              {/* Desktop Layout - Even Stages */}
              {isDesktop && stage.id % 2 === 0 && (
                <>
                  <EmptyCard />
                  <TimelineDivider number={stage.id} />
                  <TimelineCard {...stage} />
                </>
              )}

              {/* Mobile Layout */}
              {isMobile && (
                <>
                  <TimelineDivider number={stage.id} />
                  <TimelineCard {...stage} />
                </>
              )}

              {/* Desktop Layout - Odd Stages */}
              {isDesktop && stage.id % 2 !== 0 && (
                <>
                  <TimelineCard {...stage} />
                  <TimelineDivider number={stage.id} />
                  <EmptyCard />
                </>
              )}
            </Flex>
          ))}
        </Box>
      </VStack>
    </Container>
  );
};

export default AssetLifecycleTimeline;
