import {
  Box,
  Container,
  Heading,
  Image,
  Text,
  VStack,
  SimpleGrid,
  Divider,
} from "@chakra-ui/react";
import SectionHeader from "./SectionHeader";

const ClientsSection = () => {
  const clients = [
    "/assets/clients/client-01.png",
    "/assets/clients/client-02.png",
    "/assets/clients/client-03.png",
    "/assets/clients/client-04.png",
    "/assets/clients/client-05.png",
    "/assets/clients/client-06.png",
    "/assets/clients/client-07.png",
    "/assets/clients/client-08.png",
    "/assets/clients/client-09.png",
    "/assets/clients/client-010.png",
    "/assets/clients/client-011.png",
    "/assets/clients/client-012.png",
    "/assets/clients/client-013.png",
    "/assets/clients/client-014.png",
  ];

  return (
    <Box
      as="section"
      py={{ base: 16, md: 24 }}
      bg="gray.50"
    >
      <Container maxW="8xl" position="relative">
        <VStack spacing={{ base: 6, md: 8 }} mb={{ base: 12, md: 16 }}>
          <SectionHeader title="Formobi Clients" />
        </VStack>

        <SimpleGrid
          columns={{ base: 2, sm: 3, md: 4 }}
          spacing={{ base: 10, md: 16 }}
          px={{ base: 4, md: 8 }}
        >
          {clients.map((client, index) => (
            <Box key={index} bg="white" p={6} borderRadius="xl" boxShadow="sm">
              <Image
                src={client}
                alt={`Partner ${index + 1}`}
                objectFit="contain"
                w="full"
                h={{ base: "60px", md: "120px" }}
              />
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default ClientsSection;
