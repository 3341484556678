import {
  Box,
  Container,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { BiChevronRight } from "react-icons/bi";

export const FAQ_DATA = [
  {
    category: "General Information",
    items: [
      {
        question: "What does this asset management solution do?",
        answer:
          "Our solution provides end-to-end asset lifecycle management, from procurement and deployment to audits, maintenance, and write-off, ensuring maximum asset utilization and compliance.",
      },
      {
        question: "Who can benefit from this solution?",
        answer:
          "Enterprises managing a large number of physical assets, such as commercial refrigerators, coffee vending machines, POS devices, industrial equipment, and retail display units, can benefit from our streamlined tracking, audit, and maintenance workflows.",
      },
      {
        question: "How is this different from traditional asset tracking?",
        answer:
          "Unlike basic asset tracking, our solution is customized to cover the entire asset lifecycle, aligning with an organization's specific needs and work practices. With a strong focus on capturing high-quality data, automated audits, forensic insights, e-KYC, e-agreements, and analytics to enhance decision-making and optimize asset performance.",
      },
    ],
  },
  {
    category: "Features & Functionality",
    items: [
      {
        question: "Can I track assets across multiple locations?",
        answer:
          "The workflow supports geographically dispersed operations with multiple stakeholders, capturing every touchpoint of asset interactions. This ensures seamless visibility into asset deployment, condition, and movement across locations.",
      },
      {
        question: "How does the audit process work?",
        answer:
          "We provide automated audit checklists, forensic insights, and compliance tracking to ensure all assets are verified and maintained at the right intervals. Our PWA-based architecture also enables seamless onboarding of third-party audit teams on an ad-hoc basis, ensuring flexibility and scalability in asset audits.",
      },
      {
        question: "What data can be captured for each asset?",
        answer:
          "Our system records timestamps, geolocation, personnel interactions, images, QR codes, and e-documents, ensuring a comprehensive digital trail.",
      },
      {
        question: "Can the system detect unauthorized asset movement?",
        answer:
          "Yes, alerts and notification can be triggered for any unapproved transfers, deviations, or anomalies in asset usage.",
      },
    ],
  },
  {
    category: "Implementation & Integration",
    items: [
      {
        question: "How long does it take to implement this solution?",
        answer:
          "Implementation timelines vary based on deployment scale, but most enterprises can go live within days to weeks, even with large-scale customized workflows. Our flexible approach ensures rapid rollout without compromising on enterprise-specific needs.",
      },
      {
        question: "Does this integrate with existing ERP and CRM systems?",
        answer:
          "We provide secure API-based integration with your existing ERP, CRM, or custom-built systems. For large-scale data handling, we implement robust, secure mechanisms to ensure seamless and efficient data exchange as per business needs.",
      },
      {
        question: "Do you provide customized the workflow solutions?",
        answer:
          "Absolutely! Our platform is built to handle not just asset management but any large-scale, geographically distributed operations. Whether you need to manage field deployments, logistics, compliance workflows, or multi-location audits, our system is fully configurable to fit your specific needs. With robust automation and seamless integrations, we help enterprises streamline complex workflows while ensuring data accuracy and operational efficiency.",
      },
    ],
  },
  {
    category: "Security & Compliance",
    items: [
      {
        question: "How secure is my asset data?",
        answer:
          "We implement enterprise-grade security with ISO 27001-certified processes, end-to-end encryption, and role-based access controls. Continuous monitoring, WAF (Web Application Firewall) security, and industry-standard best practices help prevent unauthorized access and cyber threats, ensuring robust data protection.",
      },
      {
        question: "Does this support e-KYC and digital agreements?",
        answer:
          "We integrate with third-party services for e-KYC verification and digital agreements, ensuring seamless compliance and faster asset deployment.",
      },
    ],
  },
  {
    category: "Pricing & Support",
    items: [
      {
        question: "What is the pricing model?",
        answer:
          "Pricing is based on the number of assets managed, users, and additional features required. We offer flexible plans to suit different business needs.",
      },
      {
        question: "Do you offer support and training?",
        answer:
          "Our software is designed for intuitive use and typically requires no formal training. However, we provide training on a case-by-case basis as needed, along with support materials such as training videos.",
      },
      {
        question: "How can I get started?",
        answer:
          "Simply contact our team for a free consultation and demo. We'll help tailor the solution to your business requirements.",
      },
    ],
  },
];

const FAQSection = () => {
  return (
    <Box as="section" py={{ base: 20, md: 28 }} bg="gray.50">
      <Container maxW="8xl" mt={10}>
        <Box textAlign="center" mb={12}>
          <Heading
            as="h2"
            fontSize={{ base: "2xl", md: "3xl" }}
            color="blue.900"
            mb={4}
          >
            Frequently Asked Questions
          </Heading>
          <Text color="gray.600" fontSize={{ base: "md", md: "lg" }}>
            Everything you need to know about our asset management solution
          </Text>
        </Box>

        <VStack spacing={10} align="stretch">
          {FAQ_DATA.map((category, categoryIndex) => (
            <Box key={categoryIndex}>
              <Heading
                as="h3"
                fontSize={{ base: "xl", md: "2xl" }}
                color="blue.900"
                mb={6}
              >
                {category.category}
              </Heading>

              <Accordion allowMultiple>
                {category.items.map((faq, index) => (
                  <AccordionItem
                    key={index}
                    border="none"
                    mb={4}
                    bg="white"
                    rounded="lg"
                  >
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton
                          p={6}
                          _hover={{ bg: "gray.100" }}
                          rounded="lg"
                          borderTop={isExpanded ? "4px solid" : "none"}
                          borderTopColor={
                            isExpanded ? "blue.400" : "transparent"
                          }
                        >
                          <Box flex="1" textAlign="left">
                            <Text color={isExpanded ? "blue.500" : "gray.700"}>
                              {faq.question}
                            </Text>
                          </Box>
                          <Icon
                            as={BiChevronRight}
                            transform={isExpanded ? "rotate(90deg)" : "none"}
                            transition="transform 0.2s"
                            w={5}
                            h={5}
                            color={isExpanded ? "blue.500" : "gray.600"}
                          />
                        </AccordionButton>
                        <AccordionPanel pb={6} px={6} color="gray.600">
                          {faq.answer}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          ))}
        </VStack>
      </Container>
    </Box>
  );
};

export default FAQSection;
