import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Container,
  useColorModeValue,
  useBreakpointValue,
  Stack,
  Link,
  Divider,
} from "@chakra-ui/react";

const Terms = () => {
  // Enhanced color scheme for better contrast and readability
  const bgColor = useColorModeValue("white", "gray.900");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const accentColor = useColorModeValue("blue.700", "blue.300");
  const highlightBgColor = useColorModeValue("gray.50", "gray.700");
  const linkColor = useColorModeValue("blue.600", "blue.300");

  // Responsive font sizes
  const headingSize = useBreakpointValue({ base: "2xl", md: "3xl", lg: "4xl" });
  const subheadingSize = useBreakpointValue({ base: "xl", md: "2xl" });
  const tertiaryHeadingSize = useBreakpointValue({ base: "lg", md: "xl" });
  const bodyFontSize = useBreakpointValue({ base: "sm", md: "md" });

  // Responsive spacing
  const sectionSpacing = useBreakpointValue({ base: 8, md: 12 });
  const containerPadding = useBreakpointValue({ base: 4, md: 6, lg: 8 });

  return (
    <Box as="section" py={16} bg={bgColor}>
      <Container maxW="8xl" px={[4, 6, 8]} mt={14}>
        <Box>
          <Heading
            as="h1"
            fontSize={headingSize}
            fontWeight="bold"
            color={accentColor}
            mb={2}
            letterSpacing="tight"
            pb={2}
            borderColor={accentColor}
          >
            ServiceTiket Terms & Conditions
          </Heading>
          <Divider borderColor={borderColor} borderWidth="1px" mb={6} />
          <Text
            fontSize={bodyFontSize}
            fontStyle="italic"
            mb={{ base: 4, md: 8 }}
            color={textColor}
          >
            Effective Date: 17 March 2025
          </Text>

          {/* Introduction */}
          <Box mb={sectionSpacing}>
            <Heading
              as="h2"
              fontSize={subheadingSize}
              fontWeight="semibold"
              color={accentColor}
              mb={{ base: 3, md: 4 }}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              1. Introduction
            </Heading>

            <Text
              fontSize={bodyFontSize}
              lineHeight="tall"
              color={textColor}
              mb={4}
            >
              These Terms & Conditions ("Terms") govern your access to and use
              of ServiceTiket, a SaaS platform for Workflow Automation, Asset
              Lifecycle Management, and Service Operations ("Platform"),
              developed by <Text as="strong">Formobi Solutions Pvt. Ltd.</Text>{" "}
              ("ServiceTiket," "we," "us"). By accessing the Platform, you agree
              to these Terms.
            </Text>

            <Box
              p={{ base: 4, md: 6 }}
              bg={highlightBgColor}
              borderRadius="md"
              mb={{ base: 4, md: 6 }}
            >
              <Text
                fontSize={bodyFontSize}
                color={textColor}
                fontWeight="bold"
                mb={2}
              >
                Parties:
              </Text>
              <UnorderedList
                spacing={{ base: 1, md: 2 }}
                color={textColor}
                pl={{ base: 4, md: 6 }}
              >
                <ListItem fontSize={bodyFontSize} lineHeight="tall">
                  <Text as="strong">Enterprises ("Customers"):</Text>{" "}
                  Organizations that subscribe to ServiceTiket and onboard
                  users.
                </ListItem>
                <ListItem fontSize={bodyFontSize} lineHeight="tall">
                  <Text as="strong">Users:</Text> Individuals (employees,
                  contractors, or external partners) authorized by Enterprises
                  to use the Platform.
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>

          {/* Account & Access */}
          <Box mb={sectionSpacing}>
            <Heading
              as="h2"
              fontSize={subheadingSize}
              fontWeight="semibold"
              color={accentColor}
              mb={{ base: 3, md: 4 }}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              2. Account & Access
            </Heading>

            <Stack spacing={{ base: 3, md: 4 }}>
              <Box>
                <Heading
                  as="h3"
                  fontSize={tertiaryHeadingSize}
                  fontWeight="semibold"
                  color={accentColor}
                  mb={2}
                >
                  A. Enterprise Responsibilities
                </Heading>
                <UnorderedList
                  spacing={{ base: 1, md: 2 }}
                  color={textColor}
                  pl={{ base: 4, md: 6 }}
                  mb={{ base: 3, md: 4 }}
                >
                  <ListItem fontSize={bodyFontSize} lineHeight="tall">
                    Enterprises must provide accurate information during
                    registration and maintain account security.
                  </ListItem>
                  <ListItem fontSize={bodyFontSize} lineHeight="tall">
                    Enterprises are responsible for all activities under their
                    account, including user onboarding/offboarding.
                  </ListItem>
                </UnorderedList>
              </Box>

              <Box>
                <Heading
                  as="h3"
                  fontSize={tertiaryHeadingSize}
                  fontWeight="semibold"
                  color={accentColor}
                  mb={2}
                >
                  B. User Access
                </Heading>
                <UnorderedList
                  spacing={{ base: 1, md: 2 }}
                  color={textColor}
                  pl={{ base: 4, md: 6 }}
                  mb={{ base: 3, md: 4 }}
                >
                  <ListItem fontSize={bodyFontSize} lineHeight="tall">
                    Users are bound by these Terms and their Enterprise's
                    internal policies.
                  </ListItem>
                  <ListItem fontSize={bodyFontSize} lineHeight="tall">
                    Users must keep login credentials (e.g., SSO, MFA)
                    confidential and report unauthorized access.
                  </ListItem>
                </UnorderedList>
              </Box>
            </Stack>
          </Box>

          {/* Usage Terms */}
          <Box mb={sectionSpacing}>
            <Heading
              as="h2"
              fontSize={subheadingSize}
              fontWeight="semibold"
              color={accentColor}
              mb={{ base: 3, md: 4 }}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              3. Permitted Use
            </Heading>

            <UnorderedList
              spacing={{ base: 1, md: 2 }}
              color={textColor}
              pl={{ base: 4, md: 6 }}
              mb={{ base: 3, md: 4 }}
            >
              <ListItem fontSize={bodyFontSize} lineHeight="tall">
                Use the Platform only for lawful purposes related to workflow,
                asset, or service management.
              </ListItem>
              <ListItem fontSize={bodyFontSize} lineHeight="tall">
                Do not reverse-engineer, disrupt, or misuse the Platform (e.g.,
                spam, malware).
              </ListItem>
              <ListItem fontSize={bodyFontSize} lineHeight="tall">
                Comply with all applicable laws (e.g., GDPR, CCPA).
              </ListItem>
            </UnorderedList>
          </Box>

          {/* Data Ownership */}
          <Box mb={sectionSpacing}>
            <Heading
              as="h2"
              fontSize={subheadingSize}
              fontWeight="semibold"
              color={accentColor}
              mb={{ base: 3, md: 4 }}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              4. Data Ownership & Rights
            </Heading>

            <UnorderedList
              spacing={{ base: 1, md: 2 }}
              color={textColor}
              pl={{ base: 4, md: 6 }}
              mb={{ base: 3, md: 4 }}
            >
              <ListItem fontSize={bodyFontSize} lineHeight="tall">
                <Text as="strong">Enterprise Data:</Text> All data uploaded or
                generated on the Platform is owned by the Enterprise.
              </ListItem>
              <ListItem fontSize={bodyFontSize} lineHeight="tall">
                <Text as="strong">ServiceTiket License:</Text> Enterprises grant
                us a non-exclusive, global license to process data solely to
                operate the Platform.
              </ListItem>
            </UnorderedList>
          </Box>

          {/* Security */}
          <Box mb={sectionSpacing}>
            <Heading
              as="h2"
              fontSize={subheadingSize}
              fontWeight="semibold"
              color={accentColor}
              mb={{ base: 3, md: 4 }}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              5. Security Obligations
            </Heading>

            <Box
              p={{ base: 4, md: 6 }}
              bg={highlightBgColor}
              borderRadius="md"
              mb={{ base: 4, md: 6 }}
            >
              <UnorderedList
                spacing={{ base: 1, md: 2 }}
                color={textColor}
                pl={{ base: 4, md: 6 }}
              >
                <ListItem fontSize={bodyFontSize} lineHeight="tall">
                  <Text as="strong">ServiceTiket:</Text> Implements ISO
                  27001-certified safeguards (SSL, WAF, MFA) as outlined in our{" "}
                  <Link href="/privacy-policy" color={linkColor}>
                    Privacy Policy
                  </Link>
                  .
                </ListItem>
                <ListItem fontSize={bodyFontSize} lineHeight="tall">
                  <Text as="strong">Enterprises/Users:</Text> Must protect login
                  credentials and notify us immediately of breaches.
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>

          {/* Third-Party Services */}
          <Box mb={sectionSpacing}>
            <Heading
              as="h2"
              fontSize={subheadingSize}
              fontWeight="semibold"
              color={accentColor}
              mb={{ base: 3, md: 4 }}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              6. Third-Party Integrations
            </Heading>

            <UnorderedList
              spacing={{ base: 1, md: 2 }}
              color={textColor}
              pl={{ base: 4, md: 6 }}
              mb={{ base: 3, md: 4 }}
            >
              <ListItem fontSize={bodyFontSize} lineHeight="tall">
                ServiceTiket uses third-party providers (e.g., AWS, Twilio) for
                hosting, SMS, and security.
              </ListItem>
              <ListItem fontSize={bodyFontSize} lineHeight="tall">
                Enterprises may integrate their own third-party tools (e.g.,
                eKYC providers). ServiceTiket is not responsible for these
                services.
              </ListItem>
            </UnorderedList>
          </Box>

          {/* Termination */}
          <Box mb={sectionSpacing}>
            <Heading
              as="h2"
              fontSize={subheadingSize}
              fontWeight="semibold"
              color={accentColor}
              mb={{ base: 3, md: 4 }}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              7. Termination
            </Heading>

            <UnorderedList
              spacing={{ base: 1, md: 2 }}
              color={textColor}
              pl={{ base: 4, md: 6 }}
              mb={{ base: 3, md: 4 }}
            >
              <ListItem fontSize={bodyFontSize} lineHeight="tall">
                Enterprises may terminate their subscription at any time via the
                Platform.
              </ListItem>
              <ListItem fontSize={bodyFontSize} lineHeight="tall">
                ServiceTiket may suspend accounts for violations of these Terms
                or non-payment.
              </ListItem>
              <ListItem fontSize={bodyFontSize} lineHeight="tall">
                Data is deleted within 30 days of termination (see{" "}
                <Link href="/privacy-policy" color={linkColor}>
                  Privacy Policy
                </Link>
                ).
              </ListItem>
            </UnorderedList>
          </Box>

          {/* Liability */}
          <Box mb={sectionSpacing}>
            <Heading
              as="h2"
              fontSize={subheadingSize}
              fontWeight="semibold"
              color={accentColor}
              mb={{ base: 3, md: 4 }}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              8. Disclaimers & Limitations
            </Heading>

            <Box
              p={{ base: 4, md: 6 }}
              bg={highlightBgColor}
              borderRadius="md"
              mb={{ base: 4, md: 6 }}
            >
              <UnorderedList
                spacing={{ base: 1, md: 2 }}
                color={textColor}
                pl={{ base: 4, md: 6 }}
              >
                <ListItem fontSize={bodyFontSize} lineHeight="tall">
                  <Text as="strong">"As Is" Service:</Text> The Platform is
                  provided without warranties of merchantability or fitness for
                  a particular purpose.
                </ListItem>
                <ListItem fontSize={bodyFontSize} lineHeight="tall">
                  <Text as="strong">Liability Cap:</Text> ServiceTiket's
                  liability is limited to fees paid by the Enterprise in the
                  prior 12 months.
                </ListItem>
                <ListItem fontSize={bodyFontSize} lineHeight="tall">
                  <Text as="strong">Indirect Damages:</Text> We are not liable
                  for lost profits, data, or business opportunities.
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>
          {/* Governing Law */}
          <Box mb={12}>
            <Heading
              as="h2"
              fontSize={["2xl", "2xl"]}
              fontWeight="semibold"
              color={accentColor}
              mb={4}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              9. Governing Law
            </Heading>

            <Text fontSize="md" lineHeight="tall" color={textColor} mb={4}>
              These Terms are governed by the laws of Karnataka, India. Disputes
              will be resolved in Bangalore courts.
            </Text>
          </Box>
          {/* Updates to Terms */}
          <Box mb={12}>
            <Heading
              as="h2"
              fontSize={["2xl", "2xl"]}
              fontWeight="semibold"
              color={accentColor}
              mb={4}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              10. Updates to Terms
            </Heading>

            <Text fontSize="md" lineHeight="tall" color={textColor} mb={4}>
              We may update these Terms periodically. Enterprises will be
              notified via email or in-app alerts. Continued use constitutes
              acceptance.
            </Text>
          </Box>
          {/* Contact */}
          <Box mb={12}>
            <Heading
              as="h2"
              fontSize={["2xl", "2xl"]}
              fontWeight="semibold"
              color={accentColor}
              mb={4}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              11. Contact Us
            </Heading>

            <Text fontSize="md" lineHeight="tall" color={textColor} mb={4}>
              For privacy inquiries or to exercise your rights:
            </Text>

            <UnorderedList spacing={2} color={textColor} pl={6} mb={4}>
              <ListItem fontSize="md" lineHeight="tall">
                <Text as="strong">Data Protection Officer (DPO):</Text>{" "}
                <Link href="mailto:dpo@formobi.in" color={accentColor}>
                  dpo@formobi.in
                </Link>
              </ListItem>
              <ListItem fontSize="md" lineHeight="tall">
                <Text as="strong">Registered Address:</Text>
                Formobi Solutions Pvt. Ltd. 91Springboard, Outer Ring Road
                (ORR), Mahadevapura, Bangalore - 560048, Karnataka, India.
              </ListItem>
            </UnorderedList>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default Terms;
