import {
  Box,
  Flex,
  Text,
  Icon,
  Container,
  VStack,
  Heading,
  Grid,
  Button,
  SimpleGrid,
  Center,
  useColorModeValue,
  Image,
  Stack,
  Avatar,
} from "@chakra-ui/react";
import {
  FaDatabase,
  FaChartLine,
  FaTools,
  FaClipboardCheck,
} from "react-icons/fa";
import SectionHeader from "./SectionHeader";
import { FiArrowRight } from "react-icons/fi";

/**
 * Feature data configuration
 * Contains information for each feature card
 */
const CARDS_DATA = [
  {
    title: "Data-Driven Decisions",
    description:
      "High-quality data is essential for AI and BI insights, maximizing asset value.",
    image: "/img-20.png",
  },
  {
    title: "Smart Data Capture",
    description:
      "Seamless workflows ensure real-time, multi-source data collection.",
    image: "/img-21.png",
  },
  {
    title: "Compliance & Traceability",
    description:
      "Enrich data with timestamps, locations, images, QR codes, e-KYC, e-agreements, and digital checklists for verifiable and structured records.",
    image: "/img-31.png",
  },
  {
    title: "Maximized ROI",
    description: "Optimize asset performance with precise, AI-driven insights.",
    image: "/img-40.png",
  },
];

/**
 * FeatureCard Component
 * Displays individual feature cards with hover animations and styled elements
 * @param {Object} props - Component props
 * @param {Component} props.icon - React icon component
 * @param {string} props.title - Feature title
 * @param {string} props.description - Feature description
 * @param {string} props.iconColor - Chakra UI color for the icon
 */

const Card = ({ title, description, image, index }) => {
  return (
    <Box
      key={index}
      bg={"white"}
      boxShadow="md"
      //border={"1px solid"}
      //borderColor={"gray.100"}
      borderRadius="2xl"
      overflow="hidden"
      transition="all 0.3s ease"
      _hover={{
        transform: "translateY(-8px)",
        boxShadow: "md",
      }}
    >
      <Box position="relative" w="100%" bg={"gray.50"}>
        <Image
          src={image}
          alt={title}
          objectFit="cover"
          w="80%"
          m={"auto"}
          transition="transform 0.3s ease"
          _groupHover={{ transform: "scale(1.05)" }}
        />
      </Box>
      <VStack align="start" p={6} spacing={4} position="relative">
        <Heading
          as="h3"
          fontSize="xl"
          color={useColorModeValue("blue.900", "blue.100")}
        >
          {title}
        </Heading>
        <Text
          fontSize="md"
          color={useColorModeValue("gray.600", "gray.300")}
          lineHeight="tall"
        >
          {description}
        </Text>
      </VStack>
    </Box>
  );
};

/**
 * DataFeatureSection Component
 * Displays a grid of feature cards highlighting data management capabilities
 */
export const CardsSection = () => {
  return (
    <Box py={32} position="relative">
      <Container maxW="8xl" position="relative">
        {/* Section header */}
        <SectionHeader
          title="Data Quality The Foundation of Effective Asset Management"
          // subtitle="Accurate, reliable data fuels smarter decisions, compliance, and profitability"
        />
        <SimpleGrid
          columns={{ base: 1, md: 4 }}
          spacing={{ base: 8, lg: 8 }}
          mx="auto"
          mt={20}
        >
          {CARDS_DATA.map((feature, index) => (
            <Card key={`feature-${index}`} {...feature} />
          ))}
        </SimpleGrid>
        {/* Feature cards grid */}
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={8}
          position="relative"
          mt={20}
        ></Grid>
        <Flex justify="center" mt={4}></Flex>
      </Container>
    </Box>
  );
};

export default CardsSection;
