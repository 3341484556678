'use client'

import { useState, useEffect } from 'react'
import {
  Box,
  Flex,
  Button,
  Image,
  Link,
  Text,
  Container,
  VStack,
  HStack,
  IconButton,
  useDisclosure,
  Grid
} from '@chakra-ui/react'

// New Illustration Components with Images
const IllustrationElements = () => (
  <Box position="absolute" width="100%" height="100%" overflow="hidden" zIndex={0}>
    {/* Abstract Wave Pattern */}
    <Image
      position="absolute"
      right="-5%"
      top="0"
      width="500px"
      height="auto"
      src="/img-1.png"
      alt="Wave Pattern"
      opacity={0.1}
    />

    {/* Tech Illustration */}
    <Image
      position="absolute"
      right="5%"
      bottom="10%"
      width="300px"
      height="auto"
      src="/img-2.png"
      alt="Tech Illustration"
    />

    {/* Abstract Dots Pattern */}
    <Image
      position="absolute"
      left="0"
      top="20%"
      width="200px"
      height="auto"
      src="/img-3.png"
      alt="Dots Pattern"
      opacity={0.2}
    />

    {/* Circuit Board Pattern */}
    <Image
      position="absolute"
      left="10%"
      bottom="5%"
      width="250px"
      height="auto"
      src="/img-2.png"
      alt="Circuit Pattern"
      opacity={0.15}
    />
  </Box>
)

export default function EnhancedBlueHero() {
  return (
    <Box bg="white" position="relative" overflow="hidden">
      {/* Modern Gradient Background */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bgImage="url('/images/gradient-bg.png')"
        bgSize="cover"
        bgPosition="center"
        opacity="0.9"
      />

      {/* Add Illustrations */}
      <IllustrationElements />

      <Container maxW="7xl" position="relative" zIndex={1}>
        <VStack spacing={8} textAlign="center" py={{ base: 20, md: 32 }}>
          <Box
            p={4}
            bg="white"
            boxShadow={"xl"}
            borderRadius="2xl"
            maxW="xl"
            border="1px solid"
            borderColor="blue.100"
          >
            <Text color="blue.900" fontWeight="medium">
              🚀 Digitsed workflows in days{' '}
              <Link color="blue.500" fontWeight="bold" href="#">
                Learn more →
              </Link>
            </Text>
          </Box>

          <Text
            as="h1"
            fontSize={{ base: '4xl', md: '6xl' }}
            fontWeight="bold"
            color="blue.900"
            lineHeight="1.2"
            maxW="3xl"
          >
            ServiceTiket The Future of Asset Management
          </Text>

          <Text
            fontSize={{ base: 'lg', md: 'xl' }}
            color="blue.600"
            maxW="2xl"
            lineHeight="tall"
          >
            E2E asset management, demand planning, e-KYC, e-agreements,
            barcoding, placements, operations, audits, and compliances
          </Text>

          <HStack spacing={6} pt={8}>
            <Button
              size="lg"
              bg="blue.500"
              color="white"
              px={8}
              _hover={{
                bg: 'blue.600',
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
              transition="all 0.3s"
            >
              Get Started now
            </Button>
            <Button
              size="lg"
              variant="ghost"
              color="blue.500"
              px={8}
              _hover={{
                bg: 'blue.50',
                transform: 'translateY(-2px)',
              }}
            >
              Free consultation →
            </Button>
          </HStack>
        </VStack>
      </Container>
    </Box>
  )
}
