import { Box } from "@chakra-ui/react";
import Footer from "../components/Footer";
import ComingSoon from "./ComingSoon";
import Navbar from "../components/Navbar";

const CookiePloicy = () => {
  return (
    <Box bg="gray.50" minH="100vh">
      <Navbar />
      <ComingSoon />
      <Footer />
    </Box>
  );
};

export default CookiePloicy;
