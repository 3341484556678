import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  VStack,
  Heading,
  Container,
  Icon,
  Link,
} from "@chakra-ui/react";
import { FaCalendarCheck } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
export default function ConsultationSection() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contactus');
    window.scrollTo(0, 0);
  };
  return (
    <Box
     
      //linear-gradient(135deg, #EBF8FF 0%, #E6FFFA 100%)
      py={20}
      position="relative"
      overflow="hidden"
    >
      <Container maxW="8xl" bg={"white"}>
        <Flex
          align="center"
          justify="space-between"
          gap={12}
          flexDirection={{ base: "column", lg: "row" }}
        >
          {/* Image Section */}
          <Box
            flex="1"
            position="relative"
            _after={{
              content: '""',
              position: "absolute",
              bottom: "-20px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "80%",
              height: "20px",
              filter: "blur(20px)",
              bg: "blackAlpha.200",
              borderRadius: "full",
            }}
          >
            <Image
              src="/assets/illustration-7.png"
              alt="Expert Consultation"
              w={{ base: "280px", md: "600px" }}
              h={{ base: "280px", md: "400px" }}
              objectFit="cover"
              borderRadius="2xl"
              mx="auto"
            />
          </Box>

          {/* Content Section */}
          <VStack
            flex="1"
            align={{ base: "center", lg: "flex-start" }}
            spacing={6}
            textAlign={{ base: "center", lg: "left" }}
          >
            <Box>
              <Text
                color="blue.600"
                fontWeight="semibold"
                mb={3}
                fontSize="lg"
                textTransform="uppercase"
                letterSpacing="wide"
              >
                Expert Guidance
              </Text>
              <Heading
                color="gray.800"
                fontSize={{ base: "3xl", md: "4xl" }}
                lineHeight="shorter"
                fontWeight="bold"
                mb={4}
              >
                Transform Your Asset Management Journey
              </Heading>
              <Text
                fontSize={{ base: "lg", md: "xl" }}
                color="gray.600"
                lineHeight="tall"
                mb={6}
              >
                Schedule a{" "}
                <Text as="span" color="blue.600" fontWeight="bold">
                  complimentary consultation
                </Text>{" "}
                with our asset tracking specialists and discover innovative
                solutions tailored to your needs.
              </Text>
            </Box>
            <Link href="/contactus">
            <Button
              size="lg"
              bg={"blue.400"}
              color="white"
              px={8}
              py={7}
              fontSize="lg"
              // rightIcon={<Icon as={FaCalendarCheck} />}
              _hover={{
                bg:"blue.500"
              }}
             
            >
              Free Consultation
            </Button>
            </Link>
       
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
}
