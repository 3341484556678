import {
  Box,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Heading,
  Text,
  IconButton,
  Icon,
  VStack,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
  //   FiArchive,
  //   FiCalendar,
  FiChevronLeft,
  FiChevronRight,
  FiLayout,
  //   FiDatabase,
  //   FiLink,
  //   FiRepeat,
  //   FiTrendingUp,
} from "react-icons/fi";
import { useEffect, useRef, useState } from "react";
import SectionHeader from "../components/SectionHeader";
import ScrollToTop from "../components/ScrollToTop";
import ConsultationSection from "../components/CallToAction3";
import OurServices from "../components/OurServices";
import ServiceSection from "../components/CallToAction4";
import CallToAction from "../components/CallToAction";
import {
  FiArrowUpRight,
  FiBarChart2,
  FiBell,
  FiCamera,
  FiCheckCircle,
  FiClipboard,
  FiClock,
  FiCloud,
  FiCode,
  FiColumns,
  FiDatabase,
  FiDivide,
  FiEdit3,
  FiEye,
  FiFileText,
  FiGlobe,
  FiGrid,
  FiLink,
  FiList,
  FiLock,
  FiMapPin,
  FiMonitor,
  FiPackage,
  FiPaperclip,
  FiPlusCircle,
  FiRefreshCcw,
  FiRepeat,
  FiSearch,
  FiSend,
  FiSettings,
  FiShield,
  FiSmartphone,
  FiTag,
  FiTarget,
  FiTool,
  FiTrendingUp,
  FiUploadCloud,
  FiUserCheck,
  FiUsers,
  FiZap,
  FiArchive,
  FiCalendar,
  FiFile,
} from "react-icons/fi";

const FEATURES_DATA = {
  "Asset Management": {
    features: [
      {
        title: "Lifecycle Workflows",
        description: "Manage assets from procurement to disposal.",
        icon: FiRefreshCcw,
      },
      {
        title: "Asset Requisition",
        description: "Mobile workflows with eKyc,eAgreements, eSign.",
        icon: FiEdit3,
      },
      {
        title: "Real-Time Visibility",
        description: "Track assets and operations across locations.",
        icon: FiMonitor,
      },
      {
        title: "Mobile Tools",
        description:
          "Capture field data with checklists, QR codes, photos, locations, and part requirements.",
        icon: FiSmartphone,
      },
      {
        title: "Custom Reports",
        description: "Tailor workflows and reports to business needs.",
        icon: FiFileText,
      },
      {
        title: "Alerts",
        description: "Instant notifications for critical updates.",
        icon: FiBell,
      },
      {
        title: "Custom Templates",
        description: "Use pre-built templates for faster setup.",
        icon: FiFileText,
      },
    
      {
        title: "Spare Management",
        description: "Track spare consumption and inventory for maintenance.",
        icon: FiPackage,
      },
      {
        title: "Data Quality",
        description: "Improved data quality for audit and compliance control.",
        icon: FiDatabase,
      },
      {
        title: "Leased Contract Management",
        description:
          "Alerts for contract expirations to reduce dead inventory and rental costs.",
        icon: FiFile,
      },
      {
        title: "Full Lifecycle History",
        description:
          "Complete equipment history for better reporting and forecasting.",
        icon: FiArchive,
      },
      {
        title: "Asset Audits",
        description:
          "Easy In-house, event-based, and third party audits for compliance and accuracy.",
        icon: FiCheckCircle,
      },
      {
        title: "Asset Productivity",
        description:
          "Insights into merchandising asset productivity and alerts for lifecycle events.",
        icon: FiTrendingUp,
      },
    ],
  },
  Configuration: {
    features: [
      {
        title: "Master Data Management",
        description:
          "Centralized control for asset, location, and category data",
        icon: FiDatabase,
      },
      {
        title: "Orgs & Roles",
        description: "Define organizational structures and role-based access.",
        icon: FiUsers,
      },
      {
        title: "Dashboards & Reports",
        description:
          "Customizable dashboards and reports for actionable insights.",
        icon: FiBarChart2,
      },
      {
        title: "Outlet Management",
        description: "Efficiently manage multiple geogrpahhically disbursed outlets, locations.",
        icon: FiColumns,
      },
      {
        title: "Vendor Management",
        description: "Streamline vendor interactions and performance tracking.",
        icon: FiUserCheck,
      },
      {
        title: "Data Migration",
        description:
          "Seamless migration from existing systems with utilities like CSV uploads and templates.",
        icon: FiUploadCloud,
      },
    ],
  },
  "Mobile app": {
    features: [
      {
        title: "Easy to Use",
        description: "Intuitive design, no training required.",
        icon: FiZap,
      },
      {
        title: "Role-Specific Dashboards",
        description: "Access relevant data based on user roles",
        icon: FiLayout,
      },
      {
        title: "QR Code Scanning",
        description: "Work with right asset",
        icon: FiCamera,
      },
      {
        title: "Dynamic Checklists",
        description: "Adapt checklists to specific tasks",
        icon: FiList,
      },
      {
        title: "Location Capture",
        description: " Automatically record field locations",
        icon: FiMapPin,
      },
      {
        title: "PWA for Ad-Hoc Users",
        description: "Sandboxed Progressive Web App for temporary users.",
        icon: FiGlobe,
      },
    ],
  },
  "Ease of use": {
    features: [
      {
        title: "Action Based UI",
        description: "Only relevant actions at every stage",
        icon: FiColumns,
      },
      {
        title: "Role-Specific Views",
        description: "Custom interfaces for different roles.",
        icon: FiColumns,
      },
      {
        title: "Mobile & Web Access",
        description: "Use the system on any device",
        icon: FiMonitor,
      },
      {
        title: "Data Import",
        description: "Quickly onboard existing asset data",
        icon: FiUploadCloud,
      },
      {
        title: "Bulk Operations",
        description: "Perform multiple actions simultaneously.",
        icon: FiTool,
      },
      {
        title: "Easy Search",
        description: "Find assets, reports, and data quickly.",
        icon: FiSearch,
      },
    ],
  },
  Integrations: {
    features: [
      {
        title: "System Integrations",
        description: "Connect securely with CRM, ERP, and existing systems.",
        icon: FiLink,
      },
      {
        title: "Secure APIs",
        description: "Connect with CRM, ERP, and other systems.",
        icon: FiCode,
      },
      {
        title: "SSO Integration",
        description: "Enable Single Sign-On for streamlined access.",
        icon: FiUserCheck,
      },
      {
        title: "File Transfers",
        description: "Handle large datasets securely.",
        icon: FiUploadCloud,
      },
      
      {
        title: "Customer Portal",
        description: "Access via Progressive Web App (PWA)",
        icon: FiGlobe,
      },
    ],
  },
  Security: {
    features: [
      {
        title: "Role-based user security",
        description: "Limit access based on user roles",
        icon: FiLock,
      },
      {
        title: "Amazon AWS hosting",
        description: "SOC-compliant Amazon EC2 and S3 storage",
        icon: FiCloud,
      },
      {
        title: "TLS 1.3 Encryption",
        description: "Highest level of encryption for secure data exchanges.",
        icon: FiShield,
      },
      {
        title: "WAF Protection",
        description: "Industry Standard WAF security, blocking threats before they reach your system.",
        icon: FiShield,
      },
      {
        title: "ISO27001 Certified",
        description: "Ensures information security and management.",
        icon: FiCheckCircle,
      },
    ],
  },
};

const FeatureContent = ({ icon, title, description, color, index }) => (
  <Box
    key={index}
    bg="white"
    p={{ base: 4, sm: 6, md: 8 }}
    borderRadius={{ base: "xl", md: "2xl" }}
    boxShadow="sm"
    transition="all 0.3s ease"
    _hover={{
      transform: { base: "none", md: "translateY(-8px)" },
      boxShadow: { base: "sm", md: "md" },
    }}
    position="relative"
    overflow="hidden"
    data-testid={`feature-card-${index}`}
    height="100%"
  >
    <Box
      position="absolute"
      top={0}
      right={0}
      w={{ base: "100px", sm: "120px", md: "150px" }}
      h={{ base: "100px", sm: "120px", md: "150px" }}
      bg={`${color}.50`}
      borderBottomLeftRadius="full"
      opacity={0.3}
    />

    <VStack align="start" spacing={{ base: 3, md: 4 }} position="relative">
      <Box
        bg={`${color}.50`}
        p={{ base: 3, md: 4 }}
        borderRadius={{ base: "xl", md: "2xl" }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon
          as={icon}
          w={{ base: 5, md: 6 }}
          h={{ base: 5, md: 6 }}
          color={`${color}.500`}
        />
      </Box>

      <Heading
        as="h3"
        fontSize={{ base: "lg", md: "xl" }}
        color={`${color}.900`}
      >
        {title}
      </Heading>

      <Text
        fontSize={{ base: "sm", md: "md" }}
        color="gray.600"
        lineHeight="tall"
      >
        {description}
      </Text>
    </VStack>
  </Box>
);

const Features = () => {
  const tabListRef = useRef(null);
  const [showControls, setShowControls] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tabBg = useColorModeValue("white", "gray.800");
  const activeTabBg = useColorModeValue("gray.100", "blue.900");
  const totalTabs = Object.keys(FEATURES_DATA).length;

  useEffect(() => {
    const checkScroll = () => {
      if (tabListRef.current) {
        const { scrollWidth, clientWidth } = tabListRef.current;
        setShowControls(scrollWidth > clientWidth);
      }
    };

    checkScroll();
    window.addEventListener("resize", checkScroll);
    return () => window.removeEventListener("resize", checkScroll);
  }, []);

  const handleNavigation = (direction) => {
    const newIndex =
      direction === "left"
        ? Math.max(0, currentTabIndex - 1)
        : Math.min(totalTabs - 1, currentTabIndex + 1);

    setCurrentTabIndex(newIndex);

    if (tabListRef.current) {
      // Get the tab element at the new index
      const tabs = tabListRef.current.children;
      const targetTab = tabs[newIndex];

      if (targetTab) {
        // Calculate center position
        const containerWidth = tabListRef.current.offsetWidth;
        const tabWidth = targetTab.offsetWidth;
        const targetPosition =
          targetTab.offsetLeft - containerWidth / 2 + tabWidth / 2;

        // Smooth scroll to center the selected tab
        tabListRef.current.scrollTo({
          left: targetPosition,
          behavior: "smooth",
        });
      }
    }
  };

  const handleTabClick = (index) => {
    setCurrentTabIndex(index);

    if (tabListRef.current) {
      const tabs = tabListRef.current.children;
      const targetTab = tabs[index];

      if (targetTab) {
        const containerWidth = tabListRef.current.offsetWidth;
        const tabWidth = targetTab.offsetWidth;
        const targetPosition =
          targetTab.offsetLeft - containerWidth / 2 + tabWidth / 2;

        tabListRef.current.scrollTo({
          left: targetPosition,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <Box>
      <Navbar />
      <ScrollToTop />
      <Box as="section" py={{ base: 16, md: 16 }} bg="gray.50">
        <Container maxW="8xl" mt={20}>
          <VStack spacing={8} w="full">
            <SectionHeader title="Explore all of our features" />

            <Tabs
              position="relative"
              variant="unstyled"
              width="full"
              isLazy
              minH={"70vh"}
              mt={10}
              index={currentTabIndex}
              onChange={(index) => setCurrentTabIndex(index)}
            >
              <Box position="relative" width="full">
                {showControls && (
                  <>
                    <IconButton
                      icon={<FiChevronLeft />}
                      position="absolute"
                      left={-4}
                      top="50%"
                      transform="translateY(-50%)"
                      zIndex={2}
                      onClick={() => handleNavigation("left")}
                      rounded="full"
                      bg="gray.100"
                      _hover={{ bg: "gray.100" }}
                      size="sm"
                      display={{ base: "flex", md: "none" }}
                      aria-label="Scroll left"
                      boxShadow={"sm"}
                      color={"gray.900"}
                      isDisabled={currentTabIndex === 0}
                    />
                    <IconButton
                      icon={<FiChevronRight />}
                      position="absolute"
                      right={-4}
                      top="50%"
                      transform="translateY(-50%)"
                      zIndex={2}
                      onClick={() => handleNavigation("right")}
                      rounded="full"
                      bg="gray.100"
                      _hover={{ bg: "gray.100" }}
                      size="sm"
                      display={{ base: "flex", md: "none" }}
                      aria-label="Scroll right"
                      boxShadow={"sm"}
                      color={"gray.900"}
                      isDisabled={currentTabIndex === totalTabs - 1}
                    />
                  </>
                )}

                <Box>
                  <TabList
                    ref={tabListRef}
                    display="flex"
                    flexWrap={{ base: "nowrap", md: "wrap" }}
                    gap={2}
                    justifyContent={{ base: "flex-start", md: "center" }}
                    overflowX={{ base: "auto", md: "visible" }}
                    px={{ base: 6, md: 0 }}
                    borderBottom={"1px solid"}
                    borderBottomColor={"gray.300"}
                    sx={{
                      scrollbarWidth: "none",
                      "&::-webkit-scrollbar": { display: "none" },
                      "-webkit-overflow-scrolling": "touch",
                      scrollBehavior: "smooth",
                    }}
                  >
                    {Object.keys(FEATURES_DATA).map((key, index) => (
                      <Tab
                        key={key}
                        onClick={() => handleTabClick(index)}
                        py={3}
                        px={6}
                        flex={{ base: "0 0 auto", md: "0 1 auto" }}
                        fontSize={{ base: "sm", md: "md" }}
                        color="gray.600"
                        fontWeight={"bold"}
                        _selected={{
                          color: "blue.400",
                          borderBottom: "1px solid",
                          bg: activeTabBg,
                          borderRadius: "sm",
                        }}
                        _hover={{ bg: activeTabBg }}
                        transition="all 0.2s"
                        fontFamily={"Nunito, sans-serif"}
                      >
                        {key}
                      </Tab>
                    ))}
                  </TabList>
                </Box>
              </Box>

              <TabPanels mt={8}>
                {Object.keys(FEATURES_DATA).map((key) => (
                  <TabPanel key={key} p={0}>
                    <SimpleGrid
                      columns={{ base: 1, md: 2, lg: 3 }}
                      spacing={6}
                      w="full"
                    >
                      {FEATURES_DATA[key].features.map((feature, index) => (
                        <FeatureContent
                          key={index}
                          color="blue"
                          index={index}
                          {...feature}
                        />
                      ))}
                    </SimpleGrid>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </VStack>
        </Container>
      </Box>
      <CallToAction />

      <OurServices />
      <ConsultationSection />
      <Footer />
    </Box>
  );
};

export default Features;
