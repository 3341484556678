import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Flex,
  Icon,
  Image,
  Stack,
  VStack,
  HStack,
  useColorModeValue,
  Grid,
  Badge,
  Link,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  BiShield,
  BiLineChart,
  BiBuildingHouse,
  BiBuildings,
  BiCheckShield,
  BiTrendingUp,
} from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import { FaGears } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

const float = {
  animate: {
    y: [0, -20, 0],
  },
  transition: {
    duration: 6,
    repeat: Infinity,
    ease: "easeInOut",
  },
};

const Hero = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.700", "gray.100");
  const statsBg = useColorModeValue("white", "gray.800");
  const statsBoxShadow = useColorModeValue(
    "0 4px 6px rgba(160, 174, 192, 0.6)",
    "0 4px 6px rgba(9, 17, 28, 0.9)"
  );

  return (
    <Box
      as="section"
      py={{ base: 20, md: 28 }}
      position="relative"
      overflow="hidden"
      bg={useColorModeValue("white", "gray.900")}
    >
      {/* Diagonal Split Background */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        background={useColorModeValue(
          "linear-gradient(-45deg, blue.100 50%, transparent 50%), linear-gradient(180deg, blue.50 5%, white 25%)",
          "linear-gradient(-45deg, blue.900 50%, gray.900 50%)"
        )}
      />

      {/* Subtle Pattern Overlay */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundImage={useColorModeValue(
          "linear-gradient(-45deg, rgba(144, 205, 244, 0.08) 50%, rgba(66, 153, 225, 0.08) 50%)",
          "linear-gradient(-45deg, rgba(26, 32, 44, 0.05) 50%, rgba(66, 153, 225, 0.08) 50%)"
        )}
        opacity={0.8}
      />

      <Container
        maxW="8xl"
        position="relative"
        mt={{ base: 10, md: 20, lg: 0 }}
      >
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing={{ base: 16, lg: 16 }}
          align="center"
          justify="space-between"
        >
          {/* Left Column */}
          <VStack
            align="start"
            spacing={{ base: 10, md: 8 }}
            flex={1.5}
            position="relative"
          >
            <VStack align="start" spacing={6} w="full">
              <Heading
                borderRadius={"lg"}
                bg={"blackAlpha.50"}
                p={3}
                fontSize={"sm"}
                color={"blue.600"}
                fontWeight={"bold"}
              >
                ASSET MANAGEMENT PLATFORM
              </Heading>
              <Box>
                <Heading
                  fontSize={{ base: "2xl", md: "5xl", lg: "5xl" }}
                  lineHeight="1.1"
                  letterSpacing="-0.02em"
                  fontWeight="bold"
                  color="blue.400"
                >
                  ServiceTiket
                </Heading>
                <Heading
                  as="span"
                  color={useColorModeValue("gray.700", "gray.100")}
                  fontSize={{ base: "2xl", md: "5xl", lg: "5xl" }}
                >
                  The Future of Asset Management
                </Heading>
              </Box>
              <Heading
                fontSize={{ base: "lg", md: "2xl" }}
                fontWeight="600"
                color={useColorModeValue("gray.600", "gray.300")}
                letterSpacing="-0.01em"
                lineHeight="1.4"
              >
                E2E asset management, demand planning, e-KYC, e-agreements,
                barcoding, placements, operations, audits, and compliances
              </Heading>
            </VStack>

            {/* <Text
              fontSize={{ base: "md", md: "lg" }}
              color={textColor}
              lineHeight="1.8"
              maxW="600px"
              fontWeight="400"
            >
              Digitised workflows in days
            </Text> */}

            <Link href="/contactus">
              <Button
                bg="blue.400"
                color="white"
                fontWeight={"500"}
                py={7}
                px={10}
                borderRadius="lg"
                //rightIcon={<BsArrowRight />}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Free consultation
              </Button>
            </Link>
          </VStack>

          {/* Right Column */}
          <Box flex={1} position="relative">
            {/* <motion.div {...float}>
             
            </motion.div> */}
            <Image
              src="assets/illustration-18.png"
              alt="Hero Illustration"
              w="full"
              h="auto"
              objectFit="contain"
              loading="eager"
              quality={100}
            />
          </Box>
        </Stack>

        {/* Enhanced Stats Section */}
        <Box
          mt={{ base: 8, sm: 12, md: 16 }}
          bg={statsBg}
          rounded={{ base: "2xl", md: "3xl" }}
          p={{ base: 4, sm: 6, md: 8 }}
          boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
          position="relative"
          overflow="hidden"
          mx={{ base: 4, md: 0 }}
        >
          <Grid
            templateColumns={{
              base: "1fr",
              md: "1fr",
              lg: "repeat(3, 1fr)",
            }}
            gap={{ base: 6, sm: 8, md: 10, lg: 12 }}
            position="relative"
            zIndex={2}
          >
            {[
              {
                icon: BiBuildings,
                title: "Enterprise-Class",
                description:
                  "Serving E2E asset management for Tier-1 enterprises.",
              },
              {
                icon: BiCheckShield,
                title: "Effortless Audit Control",
                description:
                  "Real-time audits and forensic insights for seamless asset control.",
              },
              {
                icon: BiTrendingUp,
                title: "Data-Driven Insights",
                description:
                  "Informed decision-making for driving asset efficiency and profitability.",
              },
            ].map((stat, index) => (
              <HStack
                key={index}
                spacing={{ base: 4, md: 6 }}
                p={{ base: 4, sm: 5, md: 6 }}
                rounded="xl"
                flexDirection={{ base: "row", sm: "row" }}
                align={{ base: "start", sm: "start" }}
                textAlign={{ base: "left", sm: "left" }}
              >
                <Flex
                  align="center"
                  justify="center"
                  rounded="full"
                  bg="blue.50"
                  padding={{ base: 3, md: 4 }}
                  mb={{ base: 3, sm: 0 }}
                >
                  <Icon
                    as={stat.icon}
                    boxSize={{ base: 6, md: 8 }}
                    color="blue.400"
                  />
                </Flex>
                <VStack
                  align={{ base: "start", sm: "start" }}
                  spacing={{ base: 1, md: 2 }}
                >
                  <Heading fontSize={{ base: "md", sm: "lg" }} color="gray.600">
                    {stat.title}
                  </Heading>
                  <Text
                    color="gray.500"
                    fontSize={{ base: "sm", md: "md" }}
                    lineHeight="tall"
                  >
                    {stat.description}
                  </Text>
                </VStack>
              </HStack>
            ))}
          </Grid>

          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bgGradient={useColorModeValue(
              "linear(to-br, blue.50 0%, transparent 100%)",
              "linear(to-br, blue.900 0%, transparent 100%)"
            )}
            opacity={0.1}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
