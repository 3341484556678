import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Divider,
  UnorderedList,
  ListItem,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
} from "@chakra-ui/react";

const PrivacyPolicy = () => {
  // Enhanced color scheme for better contrast and readability
  const bgColor = useColorModeValue("white", "gray.900");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const accentColor = useColorModeValue("blue.700", "blue.300");
  const sectionBgColor = useColorModeValue("white", "gray.800");
  const highlightBgColor = useColorModeValue("gray.50", "gray.700");
  const tableBgColor = useColorModeValue("blue.600", "blue.500");
  const tableRowBgColor = useColorModeValue("gray.50", "gray.700");

  return (
    <Box as="section" py={16} bg={bgColor}>
      <Container maxW="8xl" px={[4, 6, 8]} mt={14}>
        <Box>
          <Heading
            as="h1"
            fontSize={["3xl", "4xl"]}
            fontWeight="bold"
            color={accentColor}
            mb={2}
            letterSpacing="tight"
            pb={2}
          >
            ServiceTiket Privacy Policy
          </Heading>
          <Divider borderColor={borderColor} borderWidth="1px" mb={6} />
          <Text fontSize="md" fontStyle="italic" mb={8} color={textColor}>
            Last Updated: 17 March 2025
          </Text>

          {/* About Section */}
          <Box mb={12}>
            <Heading
              as="h2"
              fontSize={["2xl", "2xl"]}
              fontWeight="semibold"
              color={accentColor}
              mb={4}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              1. About ServiceTiket
            </Heading>

            <Text fontSize="md" lineHeight="tall" color={textColor} mb={4}>
              ServiceTiket, developed by{" "}
              <Text as="strong">Formobi Solutions</Text>, is a{" "}
              <Text as="strong">highly configurable SaaS platform</Text>{" "}
              designed for enterprise-grade{" "}
              <Text as="strong">
                Workflow Automation, Asset Lifecycle Management, and Service
                Operations
              </Text>
              . Trusted by global brands, it empowers organizations to:
            </Text>

            <UnorderedList spacing={2} color={textColor} pl={6} mb={6}>
              <ListItem fontSize="md" lineHeight="tall">
                Digitize complex workflows with real-time, data-rich insights.
              </ListItem>
              <ListItem fontSize="md" lineHeight="tall">
                Ensure full visibility and compliance across asset lifecycles.
              </ListItem>
              <ListItem fontSize="md" lineHeight="tall">
                Reduce operational costs through scalable automation.
              </ListItem>
            </UnorderedList>

            <Box p={6} bg={highlightBgColor} borderRadius="md" mb={6}>
              <Text fontSize="md" color={textColor}>
                <Text as="strong">Trusted by:</Text> Top ice cream brands,
                global consumer goods companies, leading coffee chains, large
                machine tool manufacturers, and office automation firms.
              </Text>
            </Box>
          </Box>

          {/* Roles & Scope */}
          <Box mb={12}>
            <Heading
              as="h2"
              fontSize={["2xl", "2xl"]}
              fontWeight="semibold"
              color={accentColor}
              mb={4}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              2. Scope & Roles
            </Heading>

            <UnorderedList spacing={2} color={textColor} pl={6} mb={6}>
              <ListItem fontSize="md" lineHeight="tall">
                <Text as="strong">Enterprises (Data Controller):</Text>{" "}
                Configure workflows, onboard users, and govern data usage.
              </ListItem>
              <ListItem fontSize="md" lineHeight="tall">
                <Text as="strong">ServiceTiket (Data Processor):</Text>{" "}
                Processes data exclusively per enterprise instructions.
              </ListItem>
            </UnorderedList>
          </Box>

          {/* Data Types */}
          <Box mb={12}>
            <Heading
              as="h2"
              fontSize={["2xl", "2xl"]}
              fontWeight="semibold"
              color={accentColor}
              mb={4}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              3. Data We Process
            </Heading>

            <Heading as="h3" fontSize="xl" fontWeight="semibold" mb={2}>
              User Data
            </Heading>
            <UnorderedList spacing={2} color={textColor} pl={6} mb={4}>
              <ListItem fontSize="md" lineHeight="tall">
                Name, email, phone number, job title, department, and role.
              </ListItem>
            </UnorderedList>

            <Heading as="h3" fontSize="xl" fontWeight="semibold" mb={2}>
              Workflow/Asset Data
            </Heading>
            <UnorderedList spacing={2} color={textColor} pl={6} mb={4}>
              <ListItem fontSize="md" lineHeight="tall">
                Asset metadata (e.g., GPS location, maintenance logs), photos,
                timestamps, and device/browser information.
              </ListItem>
            </UnorderedList>

            <Heading as="h3" fontSize="xl" fontWeight="semibold" mb={2}>
              Sensitive Data
            </Heading>
            <UnorderedList spacing={2} color={textColor} pl={6} mb={4}>
              <ListItem fontSize="md" lineHeight="tall">
                <Text as="strong">
                  ServiceTiket does not store sensitive data.
                </Text>{" "}
                For eKYC (Electronic Know Your Customer) and e-Agreements,
                enterprises integrate with{" "}
                <Text as="strong">third-party providers of their choice</Text>{" "}
                (e.g., identity verification tools). ServiceTiket has no access
                to or control over this data.
              </ListItem>
            </UnorderedList>
          </Box>

          {/* Security & Compliance */}
          <Box mb={12}>
            <Heading
              as="h2"
              fontSize={["2xl", "2xl"]}
              fontWeight="semibold"
              mb={4}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              4. Security & Compliance
            </Heading>

            <Box p={6} bg={highlightBgColor} borderRadius="md" mb={4}>
              <Text fontSize="md" color={textColor} mb={2}>
                <Text as="strong">ISO 27001 Certified:</Text> ServiceTiket
                employs enterprise-grade security measures, including:
              </Text>
              <UnorderedList spacing={2} color={textColor} pl={6}>
                <ListItem fontSize="md" lineHeight="tall">
                  <Text as="strong">
                    Fortinet Web Application Firewall (WAF)
                  </Text>{" "}
                  and network firewalls for threat prevention.
                </ListItem>
                <ListItem fontSize="md" lineHeight="tall">
                  End-to-end encryption (SSL/TLS in transit, AES-256 at rest).
                </ListItem>
                <ListItem fontSize="md" lineHeight="tall">
                  Multi-Factor Authentication (MFA), role-based access controls,
                  and audit logs.
                </ListItem>
                <ListItem fontSize="md" lineHeight="tall">
                  Regular penetration testing and vulnerability scans.
                </ListItem>
              </UnorderedList>
            </Box>

            <Text fontSize="md" lineHeight="tall" color={textColor}>
              <Text as="strong">Breach Response:</Text> Enterprises notified
              within 72 hours of incident detection.
            </Text>
          </Box>

          {/* Third-Party Table */}
          <Box mb={12}>
            <Heading
              as="h2"
              fontSize={["2xl", "2xl"]}
              fontWeight="semibold"
              color={accentColor}
              mb={4}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              5. Third-Party Services
            </Heading>

            <Table variant="simple" mb={4} boxShadow="sm">
              <Thead>
                <Tr>
                  <Th bg={tableBgColor} color="white">
                    Service
                  </Th>
                  <Th bg={tableBgColor} color="white">
                    Providers
                  </Th>
                  <Th bg={tableBgColor} color="white">
                    Data Shared
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr bg={tableRowBgColor}>
                  <Td>Hosting</Td>
                  <Td>AWS, Google Cloud</Td>
                  <Td>All platform data</Td>
                </Tr>
                <Tr>
                  <Td>SMS/Push</Td>
                  <Td>Twilio, Firebase</Td>
                  <Td>Phone numbers, device tokens</Td>
                </Tr>
                <Tr bg={tableRowBgColor}>
                  <Td>Security</Td>
                  <Td>Fortinet, Cloudflare</Td>
                  <Td>Network traffic logs</Td>
                </Tr>
              </Tbody>
            </Table>

            <Text fontSize="md" lineHeight="tall" color={textColor}>
              <Text as="strong">Compliance:</Text> Subprocessors comply with
              GDPR/CCPA and use SCCs for cross-border data transfers.
            </Text>
          </Box>

          {/* Data Retention */}
          <Box mb={12}>
            <Heading
              as="h2"
              fontSize={["2xl", "2xl"]}
              fontWeight="semibold"
              color={accentColor}
              mb={4}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              6. Data Retention & Deletion
            </Heading>

            <UnorderedList spacing={2} color={textColor} pl={6} mb={4}>
              <ListItem fontSize="md" lineHeight="tall">
                Retained while the enterprise account is active.
              </ListItem>
              <ListItem fontSize="md" lineHeight="tall">
                Permanently deleted within <Text as="strong">30 days</Text> of
                contract termination.
              </ListItem>
            </UnorderedList>
          </Box>

          {/* User Rights */}
          <Box mb={12}>
            <Heading
              as="h2"
              fontSize={["2xl", "2xl"]}
              fontWeight="semibold"
              color={accentColor}
              mb={4}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              7. User Rights
            </Heading>

            <Text fontSize="md" lineHeight="tall" color={textColor} mb={4}>
              End-users must contact their enterprise (Data Controller) to:
            </Text>

            <UnorderedList spacing={2} color={textColor} pl={6} mb={4}>
              <ListItem fontSize="md" lineHeight="tall">
                Access, correct, or delete their data.
              </ListItem>
              <ListItem fontSize="md" lineHeight="tall">
                Opt out of location tracking or notifications.
              </ListItem>
            </UnorderedList>
          </Box>

          {/* Contact */}
          <Box mb={12}>
            <Heading
              as="h2"
              fontSize={["2xl", "2xl"]}
              fontWeight="semibold"
              color={accentColor}
              mb={4}
              pb={2}
              borderBottom="1px solid"
              borderColor={borderColor}
            >
              8. Contact Us
            </Heading>

            <Text fontSize="md" lineHeight="tall" color={textColor} mb={4}>
              For privacy inquiries or to exercise your rights:
            </Text>

            <UnorderedList spacing={2} color={textColor} pl={6} mb={4}>
              <ListItem fontSize="md" lineHeight="tall">
                <Text as="strong">Data Protection Officer (DPO):</Text>{" "}
                <Link href="mailto:dpo@formobi.in" color={accentColor}>
                  dpo@formobi.in
                </Link>
              </ListItem>
              <ListItem fontSize="md" lineHeight="tall">
                <Text as="strong">Registered Address:</Text>
                Formobi Solutions Pvt. Ltd. 91Springboard, Outer Ring Road
                (ORR), Mahadevapura, Bangalore - 560048, Karnataka, India.
              </ListItem>
            </UnorderedList>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
