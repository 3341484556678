// import { Box, Container, Heading, Text, Button, Icon } from "@chakra-ui/react";
// import { FaArrowRight } from 'react-icons/fa';

// export default function CTAGradient() {
//   return (
//     <Box bg="linear-gradient(135deg, #4299E1 0%, #2B6CB0 100%)" py={16}>
//       <Container maxW="4xl" textAlign="center">
//         <Heading color="white" fontSize={{ base: "3xl", md: "4xl" }} mb={4}>
//           Ready to Optimize Your Operations?
//         </Heading>
//         <Text color="whiteAlpha.900" fontSize="xl" mb={8}>
//           Join industry leaders who've transformed their asset management
//         </Text>
//         <Button
//           size="lg"
//           bg="white"
//           color="blue.600"
//           px={8}
//           rightIcon={<Icon as={FaArrowRight} />}
//           _hover={{ transform: "translateY(-2px)", bg: "gray.100" }}
//         >
//           Start Your Journey
//         </Button>
//       </Container>
//     </Box>
//   );
// }


// import { Box, VStack, Heading, Text, Button, Icon } from "@chakra-ui/react";
// import { FaRocket } from 'react-icons/fa';

// export default function CTACard() {
//   return (
//     <Box maxW="sm" mx="auto" p={8} bg="white" borderRadius="xl" boxShadow="2xl">
//       <VStack spacing={5}>
//         <Icon as={FaRocket} w={12} h={12} color="blue.500" />
//         <Heading size="lg" textAlign="center">
//           Scale Your Business Today
//         </Heading>
//         <Text textAlign="center" color="gray.600">
//           Get started with our enterprise solutions
//         </Text>
//         <Button
//           w="full"
//           colorScheme="blue"
//           size="lg"
//           bgGradient="linear(to-r, blue.400, blue.600)"
//           _hover={{
//             bgGradient: "linear(to-r, blue.500, blue.700)",
//           }}
//         >
//           Get Started
//         </Button>
//       </VStack>
//     </Box>
//   );
// }


// import { Flex, Box, Heading, Button } from "@chakra-ui/react";

// export default function CTABanner() {
//   return (
//     <Flex
//       bg="blue.500"
//       p={8}
//       borderRadius="2xl"
//       justify="space-between"
//       align="center"
//       flexDir={{ base: "column", md: "row" }}
//       gap={6}
//     >
//       <Heading color="white" size="lg">
//         Transform Your Asset Management
//       </Heading>
//       <Button
//         bg="white"
//         color="blue.500"
//         size="lg"
//         _hover={{ bg: "gray.100" }}
//         boxShadow="lg"
//       >
//         Learn More
//       </Button>
//     </Flex>
//   );
// }


// import { Box, HStack, Text, Button, Icon } from "@chakra-ui/react";
// import { FaLightbulb } from 'react-icons/fa';

// export default function CTAFloating() {
//   return (
//     <Box
//       position="fixed"
//       bottom={8}
//       right={8}
//       bg="white"
//       boxShadow="2xl"
//       borderRadius="full"
//       py={3}
//       px={6}
//     >
//       <HStack spacing={4}>
//         <Icon as={FaLightbulb} color="blue.500" />
//         <Text fontWeight="medium">Ready to upgrade?</Text>
//         <Button
//           colorScheme="blue"
//           rounded="full"
//           size="sm"
//           _hover={{ transform: "translateY(-1px)" }}
//         >
//           Get Started
//         </Button>
//       </HStack>
//     </Box>
//   );
// }


import { VStack, Heading, Button } from "@chakra-ui/react";

export default function CTAMinimal() {
  return (
    <VStack
      spacing={8}
      py={16}
      borderTop="1px"
      borderBottom="1px"
      borderColor="gray.200"
    >
      <Heading size="lg" textAlign="center">
        Ready When You Are
      </Heading>
      <Button
        variant="outline"
        colorScheme="blue"
        size="lg"
        rounded="full"
        px={12}
        _hover={{
          bg: "blue.50",
          transform: "scale(1.05)",
        }}
      >
        Begin Now
      </Button>
    </VStack>
  );
}
